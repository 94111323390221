/*
Component: <exceed-magic-form>
Usage:

Wrap this around a form to make it "magic" in that it will
- change state when edited (showing the submit and cancel buttons and highlighting the form boundaries)
- submit via XHR and replace the whole form's content with the response (using exceed-remoteform-replace)

Notes:

- This custom element extends exceed-remoteform-replace.
- This unfortunately has a dependency on jQuery (because of ujs etc)
*/

import ExceedRemoteformReplace from './exceed-remoteform-replace';
import SerializeForm from 'form-serialize';
import PubSub from 'pubsub-js';
import pubSubEvents from '../util/pubsub_event_channels';

class ExceedMagicForm extends ExceedRemoteformReplace {

  static get is() {
    return 'exceed-magic-form';
  }

  static get properties() {
    return {
      isEditing: {
        // the editing state for the form (if editing we use an observer to show the submit buttons etc)
        type: Boolean,
        value: false,
        observer: 'setEditingState'
      },
      editingClass: {
        // the class we apply to this element when editing
        type: String,
        value: 'magicform--editing'
      },
      publishIfEditing: {
        // Set this to true if other things need to know when input is changed
        type: Boolean,
        value: false
      },
      submitButtonSelector: {
        // the selector we use to find/assign the submit button for the form
        type: String,
        value: '.button--primary'
      },
      cancelButtonSelector: {
        // the selector we use to find/assign the cancel button for the form
        type: String,
        value: '.button--aux'
      },
      formSelector: {
        // the selector for the form itself (must be a child element of this)
        type: String,
        value: 'form'
      },
      confirmModalId: {
        // point this to the id of a confirm dialog if you want to use a confirm method
        type: String,
        value: ''
      },
      confirmModalSubmitSelector: {
        // The selector for the confirm button within the confirm modal
        type: String,
        value: '.button--primary'
      }  ,
      cancelUrl: {
        // optional url to replace entire form on cancel
        type: String,
        value: ''
      }
    }
  }

  /**
   * Set the editing state of the form (when form is edited we show the submit buttons and change bg colour etc)
   * NOTE: This method is observing the isEditing property
   * */
  setEditingState(isEditing) {
    if (this.editingClass.length) {
      if (isEditing) {
        this.classList.add(this.editingClass);
      } else {
        this.classList.remove(this.editingClass);
      }
    }
    if (this.publishIfEditing) {
      PubSub.publish(pubSubEvents.form_change, {hasChanged: isEditing, formId: this.id});
    }
  }

  /**
   * Handler for the input element change events
   * */
  handleInputElementChange() {
    let onChangeFormState = SerializeForm(this._formEl, { hash: false, empty: true });
    this.isEditing = this._initialFormState !== onChangeFormState;
  }

  /**
   * Bind all the input elements on the page (and record each binding so that we can unbind)
   * */
  bindInputElements() {
    this.querySelectorAll('input, textarea, select').forEach((inputEl) => {
      let boundEventHandler = this.handleInputElementChange.bind(this);
      let eventToMonitor = 'change';

      if (inputEl.type === 'text' || inputEl.type === 'number' || inputEl.type === 'textarea') {
        eventToMonitor = 'input';
      } else if (inputEl.type === 'checkbox' || inputEl.type === 'radio') {
        eventToMonitor = 'click';
      }

      inputEl.addEventListener(eventToMonitor, boundEventHandler);

      this._eventBindings.push({
        element: inputEl,
        eventType: eventToMonitor,
        handler: boundEventHandler
      });
    });
  }

  /**
   * On cancel, un-hide any elements that weren't originally hidden,
   * and re-hide any elements that were originally hidden
   * */
  resetHiddenElements() {
    // Un-hide everything that's hidden
    this._formEl.querySelectorAll('[hidden]').forEach((hiddenEl) => {
      hiddenEl.removeAttribute('hidden');
    });
    // Re-hide what was hidden initially
    this._initialHiddenEls.forEach((initialHiddenEl) => {
      initialHiddenEl.setAttribute('hidden', 'hidden');
    });
  }

  /**
   * On cancel, enable any elements that weren't originally disabled,
   * and disable any elements that were originally disabled
   * */
  resetDisabledElements() {
    // Enable everything that's disabled
    this._formEl.querySelectorAll('[disabled]').forEach((disabledEl) => {
      disabledEl.removeAttribute('disabled');
    });
    // Disable what was disabled initially
    this._initialDisabledEls.forEach((initialDisabledEl) => {
      initialDisabledEl.setAttribute('disabled', 'disabled');
    });
  }

  /**
   * On cancel, if any radio-button or checkbox triggers control dependency-toggles
     outside the form, we need to publish events for the original values;
     mark these with `data-reset-value="true"` on the checkbox or the originally
     selected radio button.
     NOTE: This may need revision to work for select elements.
   * */
  handleToggleTriggersOnReset() {
    let eventData = {}
    this.querySelectorAll('[data-reset-value="true"]').forEach((triggerEl) => {
      // add any data (from JSON in `data-trigger-data` on the target el)
      try {
        eventData = JSON.parse(triggerEl.dataset.triggerData);
      } catch(error) {
        eventData = {}
      }

      // add a target for the trigger to act on (from `data-trigger-target` on the target el)
      eventData.triggerTarget = triggerEl.dataset.triggerTarget;

      // add the value of the element to the eventData
      eventData.triggerValue = triggerEl.value;

      // publish the event
      PubSub.publish(triggerEl.dataset.trigger, eventData);
    });
  }

  handleCancelEvent(event) {
    if (this.cancelUrl.length) {
      event.preventDefault();
      $.get(this.cancelUrl)
        .done((data) => {
          this.replaceContent(data);
        })
        .fail((data) => {
          // assume that the error is handled by the server (response)
          this.replaceContent(data);
        });
    } else {
      event.preventDefault();
      this.isEditing = false;
      this._formEl.reset();
      this.resetHiddenElements();
      this.resetDisabledElements();
      PubSub.publish(pubSubEvents.form_revert);
      this.handleToggleTriggersOnReset();
    }
  }

  /**
   * Handles the form submit (showing the saving text and setting disabled etc).
   * */
  handleFormSubmitEvent(event) {
    if (this._requiresConfirm) {
      event.preventDefault();
      event.stopPropagation();
      if (this._confirmModalEl) {
        this.showConfirmModal();
      }
      return;
    }
    this.querySelectorAll(this.submitButtonSelector).forEach((submitButtonEl) => {
      if (submitButtonEl.dataset.savingText) {
        submitButtonEl.innerText = submitButtonEl.dataset.savingText;
        submitButtonEl.setAttribute('disabled', true);
      }
    });
  }

  /**
   * Bind the cancel button and form submit (and track each binding)
   * */
  bindFormEvents() {
    this.querySelectorAll(this.cancelButtonSelector).forEach((cancelButtonEl) => {
      let boundCancelHandler = this.handleCancelEvent.bind(this);
      cancelButtonEl.addEventListener('click', boundCancelHandler);
      this._eventBindings.push({
        element: cancelButtonEl,
        eventType: 'click',
        handler: boundCancelHandler
      });
    });
    // Disable submit buttons on form submit if saving text is to be shown
    if (this._formEl) {
      let boundFormSubmitHandler = this.handleFormSubmitEvent.bind(this);
      this._formEl.addEventListener('submit', boundFormSubmitHandler);
      this._eventBindings.push({
        element: this._formEl,
        eventType: 'submit',
        handler: boundFormSubmitHandler
      });
    }
  }

  /**
   * Shows the confirmation modal
   * */
  showConfirmModal() {
    this._confirmModalEl.dispatchEvent(new window.CustomEvent('show.dialog'));
  };

  /**
   * Submits the form when the confirm button is clicked
   * */
  handleConfirmButton(event) {
    event.preventDefault();
    this._requiresConfirm = false;
    this._confirmModalEl.dispatchEvent(new window.CustomEvent('hide.dialog'));
    // note we are triggering a click on the _actual_ submit button so that we trigger the rails remote form stuff correctly
    this.querySelector(this.submitButtonSelector).click();
  }

  /**
   * Sets up a confirm dialog (if there is an associated confirm dialog (by id) containing a confirm button
   * */
  setupConfirmDialog() {
    this._requiresConfirm = false;
    this._confirmButtonEl = this._confirmModalEl.querySelector(this.confirmModalSubmitSelector);
    if (this._confirmButtonEl) {
      this._requiresConfirm = true;

      let boundConfirmClickHandler = this.handleConfirmButton.bind(this);
      this._confirmButtonEl.addEventListener('click', boundConfirmClickHandler);
      this._eventBindings.push({
        element: this._confirmButtonEl,
        eventType: 'click',
        handler: boundConfirmClickHandler
      });
    }
  }

  /**
   * Besides input elements, we need to bind elements that force hidden form changes
   * So, when the form is clicked we check whether the element was one that should have fired a change event.
   * */
  additionalFormChangeTriggerHandler(event) {
    if (event.target.closest('[data-triggers-form-change]')) {
      this.isEditing = true;
    }
  }

  /**
   * Binds the form to listen for any additional triggers (by click) that would require it to activate
   * */
  setupAdditionalChangeEventHandling() {
    this._boundFormChangeTriggerHandler = this.additionalFormChangeTriggerHandler.bind(this);
    this._formEl.addEventListener('click', this._boundFormChangeTriggerHandler);

    this._eventBindings.push({
      element: this._formEl,
      eventType: 'click',
      handler: this._boundFormChangeTriggerHandler
    });
  }

  /**
   * Unbind all the events that we're binding to (when the element is removed from the dom via disconnectedCallback)
   * */
  unbindAllEvents() {
    this._eventBindings.forEach((eventBindingRecord) => {
      eventBindingRecord.element.removeEventListener(eventBindingRecord.eventType, eventBindingRecord.handler);
    });
  }

  init() {
    this._eventBindings = []; // collect all event bindings so that we can unbind

    this._formEl = this.querySelector(this.formSelector);
    if (this._formEl) {
      this._initialFormState = SerializeForm(this._formEl, { hash: false, empty: true });
      this._initialHiddenEls = this._formEl.querySelectorAll('[hidden]');
      this._initialDisabledEls = this._formEl.querySelectorAll('[disabled]');
      if (this.confirmModalId) {
        this._confirmModalEl = document.getElementById(this.confirmModalId);
      }
      this.setupAdditionalChangeEventHandling();
      if (this._confirmModalEl) {
        this.setupConfirmDialog();
      }
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.init();
    this.bindInputElements();
    this.bindFormEvents();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.unbindAllEvents();
  }

  ready() {
    super.ready();
  }
}

customElements.define('exceed-magic-form', ExceedMagicForm);

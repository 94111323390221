/*
Component: <exceed-color-input>

Description:

Adds functionality for the `.colorinput` component to dynamically
change the appareance of the included color swatch whenever
the input is changed.

Does NOT prevent invalid values from being submitted.

Usage:

```
<exceed-color-input class="colorinput">
  <div class="colorinput__swatch"></div>
  <input type="text" class="colorinput__input" />
</exceed-color-input>
```

There are no optional properties for this component.

Events:

Does not interact with events outside the component.
  
*/

import { PolymerElement } from '@polymer/polymer';
import ColorMethods from '../util/color_methods';

/* Minimum value for r, b, and g to indicate that color is close enough
   to white to treat like white */

export default class ExceedColorInput extends PolymerElement {
  static get is() {
    return 'exceed-color-input';
  }

  // Could be a private function, but regarding it is as public for testing
  isHexValueForNearlyWhiteColor(hexValue) {
    return ColorMethods.isNearlyWhiteColor(ColorMethods.rgbArrayFromHexValue(hexValue));
  }

  // Could be a private function, but regarding it is as public for testing
  isRgbValueForNearlyWhiteColor(rgbValue) {
    return ColorMethods.isNearlyWhiteColor(ColorMethods.rgbArrayFromRgbValue(rgbValue));
  }

  // Make sure the input value starts with a hash
  ensureHexFormat() {
    if (this.input.value.length && this.input.value.indexOf('#') != 0) {
      this.input.value = '#' + this.input.value;
    }
  }

  // Is the input value a valid hex color string?
  hasValidHexValue() {
    let value = this.input.value;
    if (value.length == 7) {
      return /^#[0-9A-F]{6}$/i.test(value);
    } else if (value.length == 4) {
      return /^#[0-9A-F]{3}$/i.test(value);
    }
    return false;
  }

  // Update the swatch when the input value changes
  updateSwatch() {
    // Clear old modifier classes
    this.swatch.classList.remove('colorinput__swatch--invalid');
    this.swatch.classList.remove('colorinput__swatch--light');

    this.ensureHexFormat();

    if (this.hasValidHexValue()) {
      // Show color in swatch
      this.swatch.style.backgroundColor = this.input.value;

      // Give special treatment to nearly white color
      if (this.isHexValueForNearlyWhiteColor(this.input.value)) {
        this.swatch.classList.add('colorinput__swatch--light');
      }
    } else if (!this.input.value.length) {
      // Remove background color so the swatch shows any background color
      this.swatch.style.removeProperty('background-color');
    } else {
      // Indicate invalid value
      this.swatch.classList.add('colorinput__swatch--invalid');
    }
  }

  init() {
    this.input = this.querySelector('.colorinput__input');
    this.swatch = this.querySelector('.colorinput__swatch');

    // Add input listener
    this.input.addEventListener('input', () => {
      this.updateSwatch();
    });

    // Ensure that initial nearly-white color of swatch has correct styling
    if (this.isRgbValueForNearlyWhiteColor(window.getComputedStyle(this.swatch).backgroundColor)) {
      this.swatch.classList.add('colorinput__swatch--light');
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.init();
  }
}

customElements.define('exceed-color-input', ExceedColorInput);

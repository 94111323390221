/*
Component: <exceed-simple-select>
Usage:  This select component has a limited scope:
        one select with mutually exclusive options.

        If using in a form, include a hidden input element inside this
        custom element. The hidden input field will contain that value
        that is POSTed etc (since this element doesn't contain a form
        field element of its own).

        By default this custom element will
        look for `input[type=hidden]` and set a value on that - but
        a different form element can be used via the `inputElSelector`
        property.

        If using with rails remote form, make sure that data-remote,
        data-url and data-method are included on the hidden input. You
        can also make them show a success and/or error flash notice by
        using data-success-message and/or data-error-message.

        If using this as a non-form UI element (e.g. for sorted results
        via `exceed-filter-select`), you might not need a form element
        at all.

        This can also be used for a toggled menu that doesn't behave
        as a select element - for example, the menu items can link elsewhere.
        For this usage, add the property `is-menu-only="true"`
        and give each `<li>` option the appropriate `data-href` value.

        The contents should be structured simply to work with screen readers;
        The `<ul>` can't be nested in a `<div>`, and each option `li` is
        treated as the action "button" - it can't contain a button or a link.
        (It can and must contain a `<span>` for styling and to set the selected
        option.)

```
<div class="menulist">
  <div id="specific-menulist-label" class="menulist__title">
    Label:
  </div>
  <div class="menulist__wrapper">
  <exceed-simple-select
    trigger-el-selector="#specific-menulist-trigger"
    trigger-content-selector="#specific-menulist-label"
  >
    <button id="specific-menulist-trigger"
      class="menulist__trigger"
      type="button"
      aria-haspopup="listbox"
      aria-expanded="false"
      aria-labelledby="specific-menulist-trigger"
    >
      <span class="menulist__optionname">Item 1</span>
      <svg class="menulist__triggericon" role="presentation" aria-hidden="true" focusable="false"><g>...</g></svg>
    </button>
    <ul id="specific-menulist-menu"
      class="menulist__listbox"
      role="listbox"
      aria-labelledby="specific-menulist-label"
    >
      <li id="specific-menulist-menu-option-1"
        class="menulist__option"
        role="option"
        data-option-value="1"
        data-option-text="Item 1"
        aria-selected="true"
        tabindex="-1"
      >
        <span class="menulist__optionname">Item 1</span>
      </li>
      <li id="specific-menulist-menu-option-2"
        class="menulist__option"
        role="option"
        data-option-value="2"
        data-option-text="Item 2"
        aria-selected="true"
        tabindex="-1"
      >
        <span class="menulist__optionname">Item 2</span>
      </li>
    </ul>
  </exceed-filter-select>
</div>
```

Notes:  The selected item is often not indicated within the options list
        (no checkbox or highlighting).

        The .menullist scss component is designed to be used with this.
*/

import { PolymerElement } from '@polymer/polymer';
import PubSub from 'pubsub-js';

import pubSubEvents from '../util/pubsub_event_channels';

import { downKeys, upKeys } from '../util/consts';

export default class ExceedSimpleSelect extends PolymerElement {
  static get is() {
    return 'exceed-simple-select';
  }

  static get properties() {
    return {
      selectOptionSelector: {
        // the selector for the option elements (that we bind to etc)
        type: String,
        value: '.menulist__option',
      },
      triggerContentSelector: {
        // the selector for the selected value that we show in the trigger (when opened AND closed)
        type: String,
        value: '.menulist__trigger .menulist__optionname',
      },
      selectContentSelector: {
        // the selector for the content/container for all options
        type: String,
        value: '.menulist__listbox',
      },
      selectedValue: {
        // the value currently selected
        // (currently this is only used internally - setting a default value via
        // properties will have no effect - it's listed so we can attach an observer)
        type: String,
        value: null,
        observer: 'applySelectedValue',
      },
      optionValueAttribute: {
        // The attribute we use for deriving the actual value from an option
        type: String,
        value: 'data-option-value',
      },
      optionValueTextAttribute: {
        // The attribute we use for deriving the text of an option
        type: String,
        value: 'data-option-text',
      },
      optionsVisibleClass: {
        // The class we use to toggle the show/hide on the options
        type: String,
        value: 'menulist__listbox--visible',
      },
      isOpen: {
        // whether or not the select options are open
        // (currently used only internally so we can attach an observer)
        type: Boolean,
        value: false,
        observer: 'showHideOptions',
      },
      inputElSelector: {
        // the selector for the hidden input field (used for forms etc)
        type: String,
        value: 'input[type=hidden]',
      },
      disabled: {
        // set this to true to disable the select entirely
        type: Boolean,
        value: false,
        observer: 'disableTrigger',
      },
      disabledClass: {
        type: String,
        value: 'menulist__trigger--disabled',
      },
      isMenuOnly: {
        // true if the options are, in effect, links that we use to reload the page
        // (each href is the value of a `data-href` attribute on the li)
        type: Boolean,
        value: false,
      },
      triggerElSelector: {
        // defines a selector for the trigger
        type: String,
        value: '.menulist__trigger',
      },
      updateMessage: {
        // string used to announce content update for a11y; must contain {{PARAM}} to replace
        type: String,
        value: '',
      },
      updateMessageId: {
        // id of element that contains the update message
        type: String,
        value: 'select-update-message',
      },
    };
  }

  setFocus(focusEl) {
    focusEl.focus();
    this.selectContentEl.setAttribute('aria-activedescendant', focusEl.id);
  }

  showHideOptions(isOpen) {
    let focusOnOpenEl;
    if (!this.selectContentEl) {
      return false;
    }
    if (isOpen) {
      this.selectContentEl.classList.add(this.optionsVisibleClass);
      if (this.triggerEl) {
        this.triggerEl.setAttribute('aria-expanded', 'true');
      }
      // Focus on selected or first item
      focusOnOpenEl = this.querySelector('[aria-selected="true"]') || this.selectOptions[0];
      if (focusOnOpenEl && focusOnOpenEl.focus) {
        this.setFocus(focusOnOpenEl);
      }
    } else {
      this.selectContentEl.classList.remove(this.optionsVisibleClass);
      if (this.triggerEl) {
        this.triggerEl.setAttribute('aria-expanded', 'false');
      }
    }
  }

  applySelectedValue(selectedValue) {
    // Update display of selected item, if there, replacing
    //   its contents with the contents of this button
    //   this just replaces the option inner html leaving any icon
    //   if exists
    if (this.selectedValueEl) {
      this.selectedValueEl.innerHTML = this.querySelector(
        `[${this.optionValueAttribute}="${selectedValue}"]`,
      ).innerText;
    }
  }

  disableTrigger(isDisabled) {
    if (!this.selectedValueEl) {
      return false;
    }
    let selectTriggerEl = this.selectedValueEl.parentNode;

    if (isDisabled) {
      selectTriggerEl.classList.add(this.disabledClass);
    } else {
      selectTriggerEl.classList.remove(this.disabledClass);
    }
  }

  setUpdateMessage(selectedValueText) {
    if (
      !this.isMenuOnly &&
      this.updateMessage.length &&
      selectedValueText &&
      selectedValueText.length
    ) {
      let updateMessageEl = document.getElementById(this.updateMessageId);
      if (updateMessageEl) {
        updateMessageEl.innerText = this.updateMessage.replace('{{PARAM}}', selectedValueText);
      }
    }
  }

  setValue(selectedValue, selectedValueText) {
    this.selectedValue = selectedValue;
    if (this.selectInputEl) {
      this.selectInputEl.value = this.selectedValue;

      // Trigger change events on the hidden input
      this.selectInputEl.dispatchEvent(new Event('change'));
      if (window.jQuery) {
        // NOTE: Neeeded because Rails still uses jQuery for remote forms, remove this once we update to rails 5.1
        jQuery(this.selectInputEl).trigger('change');
      }
    }

    // Change what's marked as selected in the menu
    this.selectOptions.forEach((option) => {
      if (option.getAttribute(this.optionValueAttribute) == selectedValue) {
        option.setAttribute('aria-selected', 'true');
      } else {
        option.setAttribute('aria-selected', 'false');
      }
    });

    // Return focus to trigger
    this.moveFocusToTrigger();

    // Announce the change of content
    this.setUpdateMessage(selectedValueText);

    // And trigger an event bus event (in case other components need to bind to this)
    PubSub.publish(pubSubEvents.select_change);
  }

  moveFocusToTrigger() {
    if (this.triggerEl && this.triggerEl.focus) {
      this.triggerEl.focus();
    }
  }

  moveDown(focusedEl) {
    let nextFocusEl;

    if (focusedEl.matches(this.selectOptionSelector)) {
      nextFocusEl = this.selectOptions[this.selectOptions.indexOf(focusedEl) + 1];
    }

    if (nextFocusEl && nextFocusEl.focus) {
      this.setFocus(nextFocusEl);
    }
  }

  moveUp(focusedEl) {
    let previousFocusEl;

    if (focusedEl.matches(this.selectOptionSelector)) {
      previousFocusEl = this.selectOptions[this.selectOptions.indexOf(focusedEl) - 1];
    }

    if (previousFocusEl && previousFocusEl.focus) {
      this.setFocus(previousFocusEl);
    }
  }

  handleHomeAndEnd(homeOrEnd) {
    let selectThisOption;
    if (!this.selectOptions.length) {
      return;
    } else if (homeOrEnd === 'home') {
      selectThisOption = this.selectOptions[0];
    } else {
      selectThisOption = this.selectOptions[this.selectOptions.length - 1];
    }

    if (selectThisOption) {
      this.setFocus(selectThisOption);
    }
  }

  handleEnter(focusedEl) {
    if (this.isMenuOnly) {
      if (event.target.dataset.href && event.target.dataset.href.length) {
        window.location = focusedEl.dataset.href;
      }
    } else {
      if (focusedEl.matches(this.selectOptionSelector)) {
        this.setValue(
          focusedEl.getAttribute(this.optionValueAttribute),
          focusedEl.getAttribute(this.optionValueTextAttribute),
        );
      }
    }
  }

  handleKeyboardEvents(event) {
    if (['Escape', 'Tab'].indexOf(event.key) > -1) {
      this.isOpen = false;
      this.moveFocusToTrigger();
    } else if (upKeys.indexOf(event.key) > -1) {
      event.stopPropagation();
      event.preventDefault();
      this.moveUp(event.target);
    } else if (downKeys.indexOf(event.key) > -1) {
      event.stopPropagation();
      event.preventDefault();
      this.moveDown(event.target);
    } else if (event.key === 'Enter') {
      this.handleEnter(event.target);
    } else if (event.key === 'Home') {
      event.stopPropagation();
      event.preventDefault();
      this.handleHomeAndEnd('home');
    } else if (event.key === 'End') {
      event.stopPropagation();
      event.preventDefault();
      this.handleHomeAndEnd('end');
    }
  }

  bindKeyboardEvents() {
    this._boundKeyboardEventHandler = this.handleKeyboardEvents.bind(this);
    this.addEventListener('keydown', this._boundKeyboardEventHandler);
  }

  handleSelectedOption(event) {
    if (!this.disabled) {
      this.setValue(
        event.currentTarget.closest('[role="option"]').getAttribute(this.optionValueAttribute),
        event.currentTarget.closest('[role="option"]').getAttribute(this.optionValueTextAttribute),
      );
    }
  }

  bindSelectOptions() {
    this._boundSelectOptionHandler = this.handleSelectedOption.bind(this);
    this._selectOptions = this.querySelectorAll(this.selectOptionSelector);
    this._selectOptions.forEach((selectOptionEl) => {
      selectOptionEl.addEventListener('click', this._boundSelectOptionHandler);
      this._elementEventBindings.push({
        element: selectOptionEl,
        eventType: 'click',
        handler: this._boundSelectOptionHandler,
      });
    });
  }

  handleClickOnElement(event) {
    if (!this.disabled) {
      if (this.selectContentEl.contains(event.target)) {
        let clickedOptionEl = event.target.closest('[role="option"]');
        if (this.isMenuOnly) {
          if (
            clickedOptionEl &&
            clickedOptionEl.dataset.href &&
            clickedOptionEl.dataset.href.length
          ) {
            window.location = clickedOptionEl.dataset.href;
          }
        } else {
          if (!this.selectContentEl.contains(clickedOptionEl)) {
            // We need to stop proprogation so that the document click
            // won't immediately close the menu
            event.stopPropagation();
          } else {
            this.isOpen = !this.isOpen;
          }
        }
      } else {
        this.isOpen = !this.isOpen;
      }
    }
  }

  handleClickOnDocument(event) {
    // Close all dropdowns except for the one clicked
    if (this.isOpen && !this.contains(event.target)) {
      this.isOpen = false;
    }
  }

  bindTriggers() {
    this._boundElementClickHandler = this.handleClickOnElement.bind(this);
    this.addEventListener('click', this.handleClickOnElement);
    this._boundDocumentClickHandler = this.handleClickOnDocument.bind(this);
    document.addEventListener('click', this._boundDocumentClickHandler);
  }

  unbindAllEvents() {
    this.removeEventListener('keydown', this._boundKeyboardEventHandler);
    this.removeEventListener('click', this._boundElementClickHandler);
    document.removeEventListener('click', this._boundDocumentClickHandler);
    this._elementEventBindings.forEach((eventBindingRecord) => {
      eventBindingRecord.element.removeEventListener(
        eventBindingRecord.eventType,
        eventBindingRecord.handler,
      );
    });
  }

  bindRemoteFormFeedback() {
    // NOTE: Neeeded because Rails still uses jQuery for remote forms, remove this once we update to rails 5.1
    // Also we should find a better way to manage flash notices (i.e. without external dependencies)
    if (window.jQuery && this.selectInputEl && window.Intellum && window.Intellum.flashnotice) {
      const targetEl = this.selectInputEl;
      const successMessage = targetEl.dataset.successMessage;
      const errorMessage = targetEl.dataset.errorMessage;

      if (successMessage) {
        jQuery(this.selectInputEl).on('ajax:success', () => {
          Intellum.flashnotice.show(successMessage);
        });
      }
      if (errorMessage) {
        jQuery(this.selectInputEl).on('ajax:error', () => {
          Intellum.flashnotice.show(errorMessage, 'warning');
        });
      }
    }
  }

  init() {
    this._elementEventBindings = []; // collect event bindings on elements so that we can unbind

    this.selectInputEl = this.querySelector(this.inputElSelector);
    this.selectContentEl = this.querySelector(this.selectContentSelector);
    this.selectedValueEl = this.querySelector(this.triggerContentSelector);
    this.triggerEl = this.querySelector(this.triggerElSelector);

    // Set selector for scrolling list
    this.selectOptions = Array.from(this.querySelectorAll(this.selectOptionSelector));

    // don't proceed unless we have some select content and a selected value element
    // (a hidden input element is optional)
    if (this.selectContentEl && this.selectedValueEl) {
      // Ensure that the trigger has role="button", or the navigation won't work in screen readers
      if (this.triggerEl) {
        this.triggerEl.setAttribute('role', 'button');
      }

      this.bindTriggers();
      this.bindKeyboardEvents();
      if (!this.isMenuOnly) {
        this.bindSelectOptions();
        this.bindRemoteFormFeedback();
      }
      this.disableTrigger(this.disabled);
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.init();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.unbindAllEvents();
  }
}

customElements.define('exceed-simple-select', ExceedSimpleSelect);

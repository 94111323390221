/*
Component: <exceed-remoteform-replace>
Usage: Wrap it around any form remote forms or elements where you want to replace the contents with the response.
Notes:
 * Requires jQuery (since our rails ujs uses jQuery events)
 * Assumes that jQuery is present in the DOM already
    * We're not importing it here because jQuery is huge and we don't want to import it via both the pipeline and webpack
    * When we eventually shift everything to webpack we should switch and import it here
*/

import { PolymerElement } from '@polymer/polymer';
import PubSub from 'pubsub-js';
import pubSubEvents from '../util/pubsub_event_channels';

export default class ExceedRemoteformReplace extends PolymerElement {

  static get is() {
    return 'exceed-remoteform-replace';
  }

  static get properties() {
    return {
      htmlKey: {
        type: String,
        value: 'newSectionHtml'
      },
      isToggleButton: {
        // if true we'll disable the whole element briefly whenever it's replaced
        type: Boolean,
        value: false
      },
      isReplacement: {
        // true if this rendering is a replacement of what was rendered on initial load
        type: Boolean,
        value: false
      },
      isResponseHtml: {
        type: Boolean,
        value: false
      },
      isRedirectOnSuccess: {
        // True if form validation sends json with a redirect url instead of html content
        type: Boolean,
        value: false
      },
      isCloseModalOnSuccess: {
        // True if form validation sends json with the id of the form's modal instead of html content
        type: Boolean,
        value: false
      },
      replaceElementSelector: {
        // use this to replace an element other than the form itself (use with caution)
        type: String,
        value: ''
      },
      sendUpdateContentMessage: {
        // true if we want to send a PubSub update_content message on successful save
        type: Boolean,
        value: false
      }
    }
  }

  replaceContent(data) {
    let $replaceEl = jQuery(this.replaceElementSelector);

    if (!$replaceEl.length) {
      $replaceEl = jQuery(this);
    }

    if (this.isResponseHtml) {
      $replaceEl.replaceWith(data);
    } else {
      $replaceEl.replaceWith(data[this.htmlKey]);
    }

    if (window.Intellum && Intellum.util && Intellum.util.reinitialize) {
      Intellum.util.reinitialize.trigger();
    }
  }

  /* While uploading a file, disable the submit button(s) to avoid problems */
  disableSubmitOnFileUpload(eventData) {
    this._submitButtonEls.forEach((el) => {
      el.dataset.uploadField = eventData.uploadField;
      el.setAttribute('disabled', true);
    });
  }

  /* Re-enable submit button(s) when file upload is complete (or fails) */
  enableSubmitAfterFileUpload(eventData) {
    this._submitButtonEls.forEach((el) => {
      if (el.dataset.uploadField === eventData.uploadField) {
        el.removeAttribute('disabled');
        el.dataset.uploadField = '';
      }
    });
  }

  handleButtonToggle() {
    if (!this.isToggleButton || !this.isReplacement) {
      return false;
    }

    this.firstElementChild.style.pointerEvents = 'none';

    this.addEventListener('mouseout', () => {
      setTimeout(() => {
        this.firstElementChild.style.pointerEvents = 'auto';
      }, 500);
    });
  }

  setupRemoteEl() {
    let $remoteEl = jQuery(this.querySelectorAll('[data-remote="true"]'));

    // Remove any input[type="file"] elements that don't have names before submitting
    /* Why?
       Without a name, an input isn't a param to rails.
       But - rails does see a file upload field even without a name,
       and file uploads in forms are forbidden for xhr,
       so the remote form is not sent xhr. Ruining everything.
       Happily, the input[type="file"] produced by the upload_file helper doesn't have a name, and isn't needed anymore if we've uploaded a file and are ready to submit.
       So it needs to, and can, go away before submitting.
     */
    $remoteEl.on('submit', (event) => {
      jQuery('input[type="file"]', this).each(function() {
        if (!jQuery(this).prop('name')) {
          jQuery(this).remove();
        }
      });
    });

    $remoteEl.on('ajax:success', (event, data) => {
      if ($remoteEl[0] != event.target) { return; }

      if (this.isRedirectOnSuccess && data.redirectUrl && !this._isRedirecting) {
        window.location = data.redirectUrl;
        this._isRedirecting = true;
      } else if (this.isCloseModalOnSuccess && data.updateModalId) {
        // Update the other modal (data.updateModalId)
        // The successful response to the url to get the update content will trigger destruction of the modal containing this, so we don't do that here
        PubSub.publish(pubSubEvents.modal_update, {"triggerTarget": data.updateModalId, "triggerSource": data.closeModalId});
      } else if (this.isCloseModalOnSuccess && data.closeModalId) {
        // If response signals to publish an event, do it
        if (data.publishEvent) {
          PubSub.publish(data.publishEvent, data.eventData);
        }

        PubSub.publish(pubSubEvents.modal_destroy, {"triggerTarget": data.closeModalId});
      } else {
        this.replaceContent(data);
      }
      if (this.sendUpdateContentMessage) {
        PubSub.publish(pubSubEvents.update_content, {"source": this.id});
      }
    });
    $remoteEl.on('ajax:error', (event, data) => {
      // assume that the error is handled by the server (response)
      this.replaceContent(data);
    });
  }

  // Listen for uploadfile msgs to disable submit button(s) on upload or re-enable after upload is done/fails
  listenToEventBus() {
    this._modalEventSubscriber = PubSub.subscribe(pubSubEvents.uploadfile, (msg, eventData) => {
      if (msg === pubSubEvents.uploadfile_start) {
        this.disableSubmitOnFileUpload(eventData);
      } else if (msg === pubSubEvents.uploadfile_change || msg === pubSubEvents.uploadfile_error) {
        this.enableSubmitAfterFileUpload(eventData);
      }
    });
  }

  connectedCallback() {
    super.connectedCallback();
    this._isRedirecting = false;
    this._submitButtonEls = this.querySelectorAll('[type="submit"]');
    this.listenToEventBus();
    this.setupRemoteEl();
    this.handleButtonToggle();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    PubSub.unsubscribe(this. _modalEventSubscriber);
  }
}

customElements.define('exceed-remoteform-replace', ExceedRemoteformReplace);

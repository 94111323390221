/*
Component: <exceed-filter-collector>
Usage:  Part of the `exceed-filter-*` ecosystem. This component collects the inputs from any `exceed-filter-block`
        elements it encloses. It combines the different filters with any additional query params that we need to use
        for filtering, and then it updates the url params with the new/combined filters that it has compiled (and sends
        an event so that other exceed-filter-* components can respond.
Notes:
*/

import { PolymerElement } from '@polymer/polymer';
import queryString from 'query-string';
import PubSub from 'pubsub-js';

import pubSubEvents from '../util/pubsub_event_channels';

class ExceedFilterCollector extends PolymerElement {

  static get is() {
    return 'exceed-filter-collector';
  }

  static get properties() {
    return {
      filterBlocksSelector: {
        // selector for the filter blocks that this collector collects
        type: String,
        value: 'exceed-filter-block'
      },
      filters: {
        // object used for collecting/consolidating the filters collected
        type: Object,
        value: {}
      },
      urlParams: {
        // the url parameters that this collector should apply in addition to the filters
        type: Array,
        value: []
      }
    }
  }

  /**
   * Update the url with the new filters we've compiled in this collector
   * Also publish an event so that other elements can respond
   * */
  sendUpdateEvent(isOnPageLoad) {
    if (!isOnPageLoad) {
      // we don't want to update the url on a page load (since in this case the params are already in the url -
      // also we don't want to affect the history in this case)
      let updateUrl = location.href.split('?')[0] + '?' + decodeURIComponent(queryString.stringify(this.filters, {arrayFormat: 'bracket'}));
      // don't update if another collector already handled it
      if (location.href != updateUrl) {
        history.pushState({ path: updateUrl }, '', updateUrl);
      }
    }
    PubSub.publish(pubSubEvents.filters_update, {
      // include the list of non-filter url params in case they're needed (as per exceed-filter-content-show-hide)
      urlParams: this.urlParams,
      // include the is on page load flag (so that the content element can know whether or not to apply the update)
      isOnPageLoad: isOnPageLoad
    });
  }

  /**
   * Update the filters object with any filters that have been applied PLUS any additional url params that we want
   * to add via the url-params property on the collector
   * */
  updateFilters(updateData) {
    let paramsInPageUrl;

    if (!updateData || !updateData.filterName) {
      return false;
    }

    if (updateData.filterValues && updateData.filterValues.length) {
      this.filters[updateData.filterName] = updateData.filterValues;
    } else if (this.filters[updateData.filterName]) {
      delete this.filters[updateData.filterName];
    }

    // add any params in `this.urlParams` property to the filters object
    paramsInPageUrl = queryString.parse(location.search, {arrayFormat: 'bracket'});
    this.urlParams.forEach((param) => {
      this.filters[param] = paramsInPageUrl[param];
    });

    this.sendUpdateEvent(updateData.isOnPageLoad);
  }

  /**
   * Listen to events thrown by the corresponding filter blocks and update the filters accordingly
   * */
  bindToFilterBlocks() {
    PubSub.subscribe(pubSubEvents.filters_block, (msg, eventData) => {
      this.updateFilters(eventData);
    });
    PubSub.subscribe(pubSubEvents.filters_tags, (msg, eventData) => {
      this.updateFilters(eventData);
    });
  }

  /**
   * INIT
   * */
  connectedCallback() {
    super.connectedCallback();
    this.bindToFilterBlocks();
  }
}

customElements.define('exceed-filter-collector', ExceedFilterCollector);

/*
Component: <exceed-modal>

Usage:

* Wrap content with an <exceed-modal> tag
* Or better still, wrap your content in this layout: `app/views/layouts/common/_modal.html.erb`
* To trigger a modal use this format:
  * <button type="button" data-trigger="modal.open" data-trigger-target="remote_modal_example" data-trigger-data='{"url": "/path_to_remote_modal"}'>Remote modal</button>
  * <button type="button" data-trigger="modal.open" data-trigger-target="modal_in_page_example">In-page modal</button>
* IMPORTANT
  * The value for `data-trigger-target` must match the id of the modal you are opening
  * Values in `data-trigger-data` must be in JSON format

Events:

* PubSub
  * Subscribes to:
    * `modal.open` to open (if the id in the event data matches)
    * `modal.close` to close, with warning (if the id in the event data matches)
    * `modal.destroy` to close without warning and destroy (if the id in the event data matches)
* hash change
  * Opens the modal if it is in the the dom and matches the hash value (also does this on page load)

Notes:
*/

import { PolymerElement } from '@polymer/polymer';
import PubSub from 'pubsub-js';
import pubSubEvents from '../util/pubsub_event_channels';
import { trapFocus, releaseFocus } from '../util/a11y';
import { initMarkdownEditor } from '@/markdownEditor';

export default class ExceedModal extends PolymerElement {
  static get properties() {
    return {
      openClass: {
        // add this CSS class to open the dialog
        type: String,
        value: 'modal--open',
      },
      visibleClass: {
        // add this CSS class as a second step after opening to make the dialog visible (for transitions)
        type: String,
        value: 'modal--visible',
      },
      isOpen: {
        // used internally to avoid trying to close multiple times
        type: Boolean,
        value: false,
        observer: 'handleAria',
      },
      transitionTime: {
        type: Number,
        value: 250, // should match the css transition class
      },
      bodyFreezeClass: {
        type: String,
        value: 'body--noscroll',
      },
      preserveOnClose: {
        // by default, a modal is destroyed on close; pass true to preserve it
        type: Boolean,
        value: false,
      },
      checkFormOnClose: {
        /* if a form within the modal doesn't publish updates automatically
        when changed (as within an <exceed-magic-form>), we may need to check
        for form change on close */
        type: Boolean,
        value: false,
      },
      updateUrl: {
        // pass this if we need a url to update the modal content
        // (probably the same as the url used to create the modal via xhr)
        type: String,
        value: '',
      },
      openerModal: {
        // id of a modal from which this modal was opened, for history tracking
        type: String,
        value: '',
      },
      inhibitHashChange: {
        // true if you don't want to change the url when the modal opens
        type: Boolean,
        value: false,
      },
    };
  }

  static get is() {
    return 'exceed-modal';
  }

  /**
   * Allows us to test whether an event bus event is relevant to the current instance of a modal
   * */
  isEventForThisDialog(eventData) {
    if (!eventData || !eventData.triggerTarget) {
      return false;
    }
    return eventData.triggerTarget === this.id;
  }

  /**
   * Handles the aria attributes for the modal
   * */
  handleAria(isOpen) {
    const hiddenAttr = 'aria-hidden';

    if (isOpen) {
      this.setAttribute(hiddenAttr, 'false');
    } else {
      this.setAttribute(hiddenAttr, 'true');
    }
  }

  /**
   * Returns window width minus the scrollbar (if present)
   * to offset <body> shifting when applying overflows
   * */
  getScrollbarWidth() {
    const scrollWidth = window.innerWidth - document.body.clientWidth;
    if (scrollWidth == 0) return;
    return scrollWidth + 'px';
  }

  /**
   * Opens the modal (including allowing for a transition)
   * - `inhibitHashChange` gives us an option to NOT update the history (if we're responding to a hash change)
   * */
  doModalOpen(inhibitHashChange) {
    // Save trigger so we can re-focus before opening modal
    this._modalTriggerEl = document.activeElement;
    document.body.style.marginRight = this.getScrollbarWidth();
    document.body.classList.add(this.bodyFreezeClass);
    this.classList.add(this.openClass);
    void this.offsetWidth; // force redraw
    this.classList.add(this.visibleClass);
    this.isOpen = true;
    if (!inhibitHashChange) {
      if (window.location.hash.substring(1) !== this.id) {
        window.location.hash = '#' + this.id;
      }
    }

    // Refocus on trigger so it will get remembered by trapFocus
    if (this._modalTriggerEl) {
      this._modalTriggerEl.focus();
    }

    // Trap focus, saving bound tab key handler returned from function so that #doModalClose can unbind it
    this._boundTabKeydownHandler = trapFocus(this).boundTabKeydownHandler;

    // Handle Esc key separately from trapping focus for tabs
    document.addEventListener('keydown', this._boundEscKeydownHandler);

    var editorEl =
      document.getElementById('post_editor') ||
      document.getElementById('catalog-section-markdown-editor');
    if (editorEl) {
      initMarkdownEditor(editorEl);
    }
  }

  /**
   * Closes the modal (including allowing for a transition)
   * Unless preserveOnClose, modal is destroyed on close
   * With warning dialog launched if input in modal has changed
   * - `inhibitHashChange` gives us an option to NOT update the history (if we're responding to a hash change)
   * */
  doModalClose(inhibitHashChange) {
    if (this.isOpen) {
      this.classList.remove(this.visibleClass);
      setTimeout(() => {
        this.classList.remove(this.openClass);
        // Only reset the margin offset and body freeze classes if we know we're closing the first modal opened
        if (!this.openerModal) {
          document.body.style.marginRight = '';
          document.body.classList.remove(this.bodyFreezeClass);
        }
      }, this.transitionTime);
      if (!inhibitHashChange) {
        /* If another modal launched this one, we need to move the history forward
           to that modal; otherwise, we move it forward to the main page (no hash) */
        let hashToPush = this.openerModal ? '#' + this.openerModal : '';
        history.pushState(
          '',
          document.title,
          window.location.pathname + hashToPush + window.location.search,
        );
      }
      this.isOpen = false;

      releaseFocus(this._modalTriggerEl, this._boundTabKeydownHandler);
      document.removeEventListener('keydown', this._boundEscKeydownHandler);
    }
  }

  /**
   * Destroy the modal
   * */
  doModalDestroy() {
    if (this.parentNode) {
      setTimeout(() => {
        // Recheck of parentNode existence needed for tests
        if (this.parentNode) {
          this.parentNode.removeChild(this);
        }
      }, this.transitionTime);
    }
  }

  /**
   * Respond to events
   * */
  openModal() {
    this.doModalOpen(this.inhibitHashChange);
  }

  closeModal() {
    if (!this.preserveOnClose) {
      let closeDependingOnFormChange = () => {
        if (this._formHasChanged) {
          let dialogEl = document.getElementById(this.id + '-dialog');
          dialogEl.dispatchEvent(new CustomEvent('show.dialog', { detail: { trigger: null } }));

          // Temporarily remove Esc function from the modal so the dialog can get it
          // and restore it on dialog close
          document.removeEventListener('keydown', this._boundEscKeydownHandler);
          dialogEl.addEventListener('hide.dialog', () => {
            document.addEventListener('keydown', this._boundEscKeydownHandler);
          });
        } else {
          this.doModalClose();
          this.doModalDestroy();
        }
      };

      // Before closing, open a warning dialog if data in modal has changed
      if (this.checkFormOnClose) {
        // Need time to get form_change event if we're checking that on close
        let checkForm = () => {
          setTimeout(() => {
            if (this._formWasChecked) {
              closeDependingOnFormChange();
            } else {
              checkForm();
            }
          }, 20);
        };
        checkForm();
      } else {
        closeDependingOnFormChange();
      }
    } else {
      this.doModalClose();
    }
  }

  destroyModal() {
    this.doModalClose();
    this.doModalDestroy();
  }

  /**
   * Listen for event bus events and handle any events that are directed towards this instance
   * */
  listenToEventBus() {
    this._modalEventSubscriber = PubSub.subscribe(pubSubEvents.modal, (msg, eventData) => {
      if (this.isEventForThisDialog(eventData)) {
        if (msg === pubSubEvents.modal_open) {
          this.openModal();
        } else if (msg === pubSubEvents.modal_close) {
          this.closeModal();
        } else if (msg === pubSubEvents.modal_destroy) {
          this.destroyModal();
        }
      }
    });
  }

  /**
   * Set up modal to warn on close if any form input within has been changed
   * */
  initFormListener() {
    this._formHasChanged = false;

    if (!this.preserveOnClose) {
      PubSub.subscribe(pubSubEvents.form, (msg, eventData) => {
        if (msg === pubSubEvents.form_change) {
          if (
            eventData.hasChanged &&
            eventData.formId &&
            this.querySelector('#' + eventData.formId)
          ) {
            this._formHasChanged = true;
          } else {
            this._formHasChanged = false;
          }
          // If this is a form checked for change only on close, save flag to say we got a response
          if (this.checkFormOnClose && this.querySelector('#' + eventData.formId)) {
            this._formWasChecked = true;
          }
        }
      });
    }
  }

  /**
   * Open a modal if the hash matches the id of this modal instance
   * This works only on page load
   * All calls to #doModalOpen or #doModalClose pass true for inhibitOnClose;
   *   we've already added the hash, so we shouldn't again
   * */
  openModalMatchingHash() {
    const urlHash = window.location.hash.substring(1);
    if (urlHash) {
      if (urlHash === this.id) {
        // Element might still exist but be disconnected, so check for parent el
        if (this.parentElement && !this.isOpen) {
          this.doModalOpen(true);
        }
      } else if (urlHash == this.openerModal) {
        this.doModalClose(true);
      }
    } else {
      this.doModalClose(true);
    }
  }

  /**
   * Listen for history changes (via hashchange) and open and close accordingly
   * */
  handleHistoryEvents() {
    window.addEventListener('hashchange', () => {
      this.openModalMatchingHash();
    });
  }

  /**
   * Listen for the pressing of the escape key (and fire a close event when clicked)
   * */
  escKeydownHandler(event) {
    if (this.isOpen && (event.key === 'Escape' || event.key === 'Esc' || event.key === 27)) {
      var publishEvent = pubSubEvents.modal_close;
      PubSub.publish(publishEvent, { triggerTarget: this.id });
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this._modalTriggerEl = null;
    this._boundTabKeydownHandler = null;
    this._boundEscKeydownHandler = this.escKeydownHandler.bind(this);
    this.initFormListener();
    this.listenToEventBus();
    this.handleHistoryEvents();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    PubSub.unsubscribe(this._modalEventSubscriber);
    if (!this.preserveOnClose) {
      PubSub.unsubscribe(pubSubEvents.form_change);
    }
    window.removeEventListener('hashchange', this.openModalMatchingHash);
  }

  constructor() {
    super();
  }
}

customElements.define('exceed-modal', ExceedModal);

/*
Component: <exceed-filter-block>
Usage:  Part of the `exceed-filter-*` ecosystem. This component manages the input from a block of checkboxes and
        throws an event. (`exceed-filter-collector` listens to and manages the events for multiple blocks)
Notes:
*/

import { PolymerElement } from '@polymer/polymer';
import PubSub from 'pubsub-js';

import pubSubEvents from '../util/pubsub_event_channels';
import queryString from "query-string";

export default class ExceedFilterBlock extends PolymerElement {

  static get is() {
    return 'exceed-filter-block';
  }

  static get properties() {
    return {
      filterName: {
        // the name of the filter (gets used in the url and for matching to results)
        type: String,
        value: ''
      },
      filterValues: {
        // the values that we collect in this block
        type: Array,
        value: []
      },
      inputSelector: {
        // the selector for the elements in this block that we listen to (typically checkboxes)
        type: String,
        value: 'input[type=checkbox]'
      },
      inputsToUpdateId: {
        // the id of a container with input (whose name matches filter param name) that needs to be updated when filter is changed
        type: String,
        value: ''
      },
      limitToOneFilter: {
        // if this is passed as true, only one filter per block can be selected at a time
        // this only applies when the input is changed;
        // if it loads with multiple checks because of params, ¯\_(ツ)_/¯
        type: Boolean,
        value: false
      },
      useArrayParams: {
        // Make this true if you'll have a filter with multiple values
        type: Boolean,
        value: false
      },
      submitButtonEl: {
        // pass a selector for a submit button if that is needed to submit choice;
        // otherwise, filtering changes upon checkbox selection
        type: String,
        value: ''
      }
    };
  }

  /**
   * If the `limitToOneFilter` property is true, this method will toggle any other currently selected
   * items to be "off"
   * */
  limitCheckedInputs(checkedInput) {
    if (this.limitToOneFilter) {
      this.querySelectorAll(`${this.inputSelector}:checked`).forEach((inputEl) => {
        if (inputEl != checkedInput) {
          inputEl.checked = false;
        }
      });
    }
  }

  /**
   * Sets the `filterValues` property on the element
   * */
  setValues() {
    this.filterValues = this.useArrayParams ? [] : null
    this.querySelectorAll(`${this.inputSelector}:checked`).forEach((inputEl) => {
      if (Array.isArray(this.filterValues)) {
        this.filterValues.push(inputEl.value);
      } else {
        this.filterValues = inputEl.value;
      }
    });
    // Change the value of input field (within specified container) whose name is the param name
    // (Needed sometimes to keep search updated with filter changes)
    if (this.inputsToUpdateEl) {
      let inputFieldName = `[name="${this.filterName}"]`;
      let paramInputEls = this.inputsToUpdateEl.querySelectorAll(inputFieldName);
      if (paramInputEls.length) {
        paramInputEls.forEach((inputEl) => {
          inputEl.value = this.filterValues;
        });
      }
    }
  }

  /**
   * Sends an update event (so that the filter content and other elements can respond)
   * */
  sendUpdateEvent(isOnPageLoad) {
    PubSub.publish(pubSubEvents.filters_block, {
      filterName: this.filterName,
      filterValues: this.filterValues,
      isOnPageLoad: isOnPageLoad
    });
  }

  /**
   * Binds a click event on all checkboxes in the block
   * */
  bindCheckboxes() {
    this.querySelectorAll(this.inputSelector).forEach((inputEl) => {
      inputEl.addEventListener('click', (event) => {
        this.limitCheckedInputs(event.target);
        if (!this._submitButtonEl) {
          this.setValues();
          this.sendUpdateEvent();
        }
      });
    });
  }

  /**
   * Binds a click event on the designated submit button (if any)
   * */
  bindSubmitButton() {
    if (this._submitButtonEl) {
      this._submitButtonEl.addEventListener('click', (event) => {
        this.setValues();
        this.sendUpdateEvent();
      });
    }
  }

  /**
   * Update filter block when filters are changed by filter tags
   */
  bindToEvents() {
    PubSub.subscribe(pubSubEvents.filters_tags, (msg, eventDetail) => {
      if (eventDetail.filterName == this.filterName) {
        this.querySelectorAll(`${this.inputSelector}`).forEach((inputEl) => {
          let isChecked;
          if (Array.isArray(eventDetail.filterValues)) {
            isChecked = eventDetail.filterValues.includes(inputEl.value);
          } else {
            isChecked = eventDetail.filterValues == inputEl.value;
          }

          if (isChecked) {
            inputEl.checked = true;
          } else {
            inputEl.checked = false;
          }
        });
      }
    });
  }

  /**
   * Updates the filter block according to any values in the url
   * */
  updateViaUrl() {
    let filtersFromUrl = queryString.parse(location.search, {arrayFormat: 'bracket'});
    let filterValuesForBlock = filtersFromUrl[this.filterName];

    this.filterValues = filterValuesForBlock || []

    this.applyFilterValuesToCheckboxes();

    setTimeout(() => {
      // ensure that the filter collector is receiving events before publishing...
      // (since this happens on initial load)
      this.sendUpdateEvent(true);
    }, 50);
  }

  /**
   * Makes the corresponding checkboxes "checked" (e.g. on page load)
   * */
  applyFilterValuesToCheckboxes() {
    this.querySelectorAll(this.inputSelector).forEach((checkboxEl) => {
      if (Array.isArray(this.filterValues)) {
        checkboxEl.checked = this.filterValues.indexOf(checkboxEl.value) > -1;
      } else {
        checkboxEl.checked = checkboxEl.value == this.filterValues
      }
    });
  }

  /**
   * Binds the history.back event to the updating of the filters
   * (to ensure that the filters are showing correctly when you press the back button).
   */
  handleHistoryBack() {
    window.addEventListener('popstate', () => {
      this.updateViaUrl();
    });
  }

  initComponent() {
    this._submitButtonEl = (this.submitButtonEl.length) ? document.querySelector(this.submitButtonEl) : null;
    this.inputsToUpdateEl = document.getElementById(this.inputsToUpdateId);
    this.bindCheckboxes();
    this.bindSubmitButton();
    this.bindToEvents();
  }

  /**
   * INIT
   * */
  connectedCallback() {
    super.connectedCallback();
    this.initComponent();
    this.updateViaUrl();
    this.handleHistoryBack();
  }
}

customElements.define('exceed-filter-block', ExceedFilterBlock);

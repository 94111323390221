/*
Component: <exceed-nav-menu>
Usage: Dropdown menus with additional navigation or navigation-related options,
generally accessed from the site header.

If this is meant to mimic a `<select>` element,
with the selected item shown in the trigger,
use `<exceed-filter-select>` or `<exceed-simple-select>` instead.

To use this, wrap it around the menu and its trigger
and make sure to provide classes for the triggerEl and menuEl.
*/

import { PolymerElement } from '@polymer/polymer';
import axios from 'axios';

export default class ExceedNavMenu extends PolymerElement {

  static get is() {
    return 'exceed-nav-menu';
  }

  static get properties() {
    return {
      triggerElSelector: {
        // selector for the trigger
        type: String,
        value: '.navmenu__trigger'
      },
      blurTriggerElSelector: {
        // selector for closing the menu i.e. backdrop
        type: String,
        value: '.navmenu__backdrop'
      },
      menuElSelector: {
        // selector for the menu
        type: String,
        value: '.navmenu__menu'
      },
      menuVisibleClass: {
        // class to add to this item when it has been opened
        type: String,
        value: 'navmenu__menu--visible'
      },
      triggerActiveClass: {
        // class to add to the trigger when it has been activated - generally not used
        type: String,
        value: ''
      },
      optionElSelector: {
        // defines internal container for each link or action
        type: String,
        value: 'li'
      },
      focusElSelector: {
        // defines an element in the content that gets focused when content is opened
        type: String,
        value: '[data-is-menu-focus]'
      },
      dynamicContentContainerSelector: {
        // defines element that holds content to be loaded
        type: String,
        value: ''
      },
      dynamicContentEndpoint: {
        // endpoint from which to get dynamic content
        type: String,
        value: ''
      },
      isDynamicContentDelayed: {
        // true = delay load until menu is opened, false = load content on component init
        type: Boolean,
        value: false
      },
      isOpen: {
        // whether or not the menu is open (used internally to attach an observer)
        type: Boolean,
        value: false,
        observer: 'showHideMenu'
      }
    }
  }

  getDynamicContent() {
    if (!this._isDynamicContentLoaded) {
      axios.get(this.dynamicContentEndpoint)
      .then((response) => {
        this._dynamicContentEl.innerHTML = response.data;
        this._isDynamicContentLoaded = true;

        // rebind blur events for new elements
        this.bindBlurEvent();
      });
    }
  }

  showHideMenu(isOpen) {
    if (this._triggerEl && this._menuEl) {
      if (isOpen) {
        if (this._hasDynamicContent && this.isDynamicContentDelayed) {
          this.getDynamicContent();
        }
        this._menuEl.classList.add(this.menuVisibleClass);
        this._triggerEl.setAttribute('aria-expanded', 'true');
        if (this.triggerActiveClass.length) {
          this._triggerEl.classList.add(this.triggerActiveClass);
        }
        if (this._focusEl) {
          // needs delay till after CSS transition
          setTimeout(() => {
            this._focusEl.focus();
          }, 50);
        }
      } else if (!this._triggerEl.hasAttribute('disabled')) {
        this._menuEl.classList.remove(this.menuVisibleClass);
        this._triggerEl.setAttribute('aria-expanded', 'false');
        if (this.triggerActiveClass.length) {
          this._triggerEl.classList.remove(this.triggerActiveClass);
        }

        // This is for resetting any active exceed-toggle-button elements within
        // the Topics menu once the nav menu is closed
        if (window.innerWidth > 480) {
          const toggleActiveButtons = document.querySelectorAll(".categoriesmenu__aside--current");
          if (toggleActiveButtons) {
            window.setTimeout(() => {
              toggleActiveButtons.forEach((activeEl) => {
                activeEl.classList.remove('categoriesmenu__aside--current');
                activeEl.setAttribute('aria-expanded', 'false');
                activeEl.setAttribute('aria-hidden', 'true');
              })
            }, 300);
          }
        }
      }
    }
  }

  handleHideMenuEvent(event) {
    this.isOpen = false;
    event.stopPropagation();
  }

  handleClickOnNavMenu(event) {
    if (this._menuEl.contains(event.target)) {
      let clickedOptionEl = event.target.closest(this.optionElSelector);
      if (!this._menuEl.contains(clickedOptionEl)) {
        // We need to stop propogation so that the document click
        // won't immediately close the menu
        event.stopPropagation();
      }
    } else {
      this.isOpen = !this.isOpen;
    }
  }

  handleClickOnDocument(event) {
    // Close all dropdowns except for the one clicked
    if (this.isOpen && (
      !this.contains(event.target)
      || (this._blurTriggerEl && this._blurTriggerEl.contains(event.target))
    )) {
      this.isOpen = false;
    }
  }

  handleBlurEvent(event) {
    // If the element that gets focus isn't in this, close the menu
    if (this.isOpen) {
      let nextTarget = event.relatedTarget;
      /* relatedTarget doesn't work for IE;
         it sets the next focus before firing the blur event
         https://stackoverflow.com/a/49325196 */
      if (window.MSInputMethodContext && document.documentMode) {
        nextTarget = document.activeElement;
      }
      if (nextTarget && !this.contains(nextTarget)) {
        this.isOpen = false;
      }
    }
  }

  bindTriggers() {
    this._boundMenuClickHandler = this.handleClickOnNavMenu.bind(this);
    this.addEventListener('click', this._boundMenuClickHandler);
    this._boundDocumentClickHandler = this.handleClickOnDocument.bind(this);
    document.addEventListener('click', this._boundDocumentClickHandler);
    if (this._blurTriggerEl) {
      this._blurTriggerEl.addEventListener('click', this._boundDocumentClickHandler);
    }
    this._boundHideMenuEventHandler = this.handleHideMenuEvent.bind(this);
    this.addEventListener('navmenu.hide', this._boundHideMenuEventHandler);
  }

  bindBlurEvent() {
    this._boundBlurEventHandler = this.handleBlurEvent.bind(this);
    this._focusableEventEls = [];
    let focusableEls = this._menuEl.querySelectorAll('button, [href], [tabindex]:not([tabindex="-1"]), input');
    focusableEls.forEach((focusableEl) => {
      focusableEl.addEventListener('blur', this._boundBlurEventHandler);
      this._focusableEventEls.push(focusableEl);
    })
  }

  unbindAllEvents() {
    this.removeEventListener('click', this._boundMenuClickHandler);
    this.removeEventListener('click', this._boundTriggerClickHandler);
    document.removeEventListener('click', this._boundDocumentClickHandler);
    this._focusableEventEls.forEach((focusableEl) => {
      focusableEl.removeEventListener('blur', this._boundBlurEventHandler);
    });
    this.removeEventListener('navmenu.hide', this._boundHideMenuEventHandler);
  }

  init() {
    this._triggerEl = this.querySelector(this.triggerElSelector);
    this._blurTriggerEl = this.querySelector(this.blurTriggerElSelector);
    this._menuEl = this.querySelector(this.menuElSelector);
    if (this._triggerEl && this._menuEl) {
      this.bindTriggers();
      this.bindBlurEvent();
      this._focusEl = this.querySelector(this.focusElSelector);
    }
    if (this.dynamicContentContainerSelector.length && this.dynamicContentEndpoint.length) {
      this._dynamicContentEl = this.querySelector(this.dynamicContentContainerSelector);
      if (this._dynamicContentEl) {
        this._hasDynamicContent = true;
        if (!this.isDynamicContentDelayed) {
          this.getDynamicContent();
        }
      }
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.init();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.unbindAllEvents();
  }
}

customElements.define('exceed-nav-menu', ExceedNavMenu);

/*
Component: <exceed-autocomplete-simple>

Description:
* The bare bones simple way of doing auto complete.
* And endpoint will be called with the field that this component wraps.
* The results from that endpoint will be appended to the custom element (after the input field).
* This will only work if the results are actionable of themselves (i.e. if the results are links).

Example Usage:

<exceed-autocomplete-simple end-point="/responsibilities/autocomplete?taggable[id]=<%= resource&.id %>&taggable[type]=<%= resource&.class.to_s %>">
  <%= text_field("responsibility", "name", id: "add_responsibility_name", class: "studentform__input", autocomplete: 'off', placeholder: 'Search for an existing responsibility') %>
</exceed-autocomplete-simple>

*/

import { PolymerElement } from '@polymer/polymer';
import axios from '../util/axios'

class ExceedAutocompleteSimple extends PolymerElement {
  static get is() {
    return 'exceed-autocomplete-simple';
  }

  static get properties() {
    return {
      endPoint: {
        type: String
      },
      method: {
        type: String,
        value: 'POST' // we should eventually make the default a GET
      },
      methodNormalised: {
        type: String,
        computed: 'normaliseMethodString(method)'
      },
      isDropdown: {
        type: Boolean,
        value: false
      },
      forceAutocompleteValue: {
        type: Boolean,
        value: false
      },
      resultsElSelector: {
        type: String,
        value: '#autocomplete-results'
      }
    }
  }

  normaliseMethodString(endPointString) {
    endPointString = endPointString.toLowerCase();
    if (endPointString !== 'get' && endPointString !== 'post') {
      // we should eventually make the default a GET
      endPointString = 'post';
    }
    return endPointString;
  }

  getResults(nextPageNumber) {
    nextPageNumber = nextPageNumber || 1;
    let postData = {};
    postData[this.inputEl.name] = this.inputEl.value;
    if (this.methodNormalised === 'get') {
      postData = { params: postData };
      postData.params.page = nextPageNumber;
    }
    if (this.inputEl.value) {
      axios[this.methodNormalised](this.endPoint, postData).then((response) => {
        this.resultsEl.innerHTML = '';
        this.resultsEl.appendChild(document.createRange().createContextualFragment(response.data));
        this.bindPaginationEvents();
      });
    } else {
      this.resultsEl.innerHTML = '';
    }
  }

  bindKeyEvents() {
    this.inputEl.addEventListener('keyup', Intellum.util.debounce(() => {
      this.getResults();
      this.dispatchEvent(new CustomEvent('autocompletesimple.search'));
    }, 500));
  }

  bindMouseEvents() {
    if (this.isDropdown) {
      document.addEventListener('click', () => {
        this.resultsEl.innerHTML = '';
      });
    }
  }

  bindPaginationEvents() {
    if (this.resultsEl) {
      this.nextPageEl = this.resultsEl.querySelector('*[data-behaviour="goToNextPage"]');
      this.prevPageEl = this.resultsEl.querySelector('*[data-behaviour="goToPrevPage"]');
    }
    if (this.nextPageEl) {
      var nextPageNumber = this.nextPageEl.dataset.nextPageNumber || 0;
      this.nextPageEl.addEventListener('click', Intellum.util.debounce(() => {
        this.getResults(nextPageNumber);
        this.dispatchEvent(new CustomEvent('autocompletesimple.search'));
      }, 500));
    }
    if (this.prevPageEl) {
      var prevPageNumber = this.prevPageEl.dataset.prevPageNumber || 0;
      this.prevPageEl.addEventListener('click', Intellum.util.debounce(() => {
        this.getResults(prevPageNumber);
        this.dispatchEvent(new CustomEvent('autocompletesimple.search'));
      }, 500));
    }
  }

  init() {
    this.inputEl = this.querySelector('input');
    this.resultsEl = this.querySelector(this.resultsElSelector);
    this.bindKeyEvents();
    this.bindPaginationEvents();
    this.bindMouseEvents();
  }

  connectedCallback() {
    super.connectedCallback();
    this.init();
  }
}

customElements.define('exceed-autocomplete-simple', ExceedAutocompleteSimple);

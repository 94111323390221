/*
Component: <exceed-table-utils>
Usage: Wrap around a table to give it extra functionality
Notes:

To make the rows clickable:
* Set an `is-clickable-rows` attribute on this element to true
* Add a `data-href` attribute to each row - point it to the correct url
* If you have some cells that should NOT be clickable (e.g. checkboxes or actions) add a `data-block-row-click`
  to those cells.

*/

import { PolymerElement } from '@polymer/polymer';
import PubSub from 'pubsub-js';
import pubSubEvents from '../util/pubsub_event_channels';

class ExceedTableUtils extends PolymerElement {

  static get properties() {
    return {
      isClickableRows: {
        // makes the rows of this table clickable - expects a `data-href` attribute on each row
        type: Boolean,
        value: false
      },
      hoverContentSelector: {
        // the indicated data selector marks a cell holding hover content for the row
        type: String,
        value: '[data-row-hover-content]'
      },
      hoverContentClass: {
        // the class to add to hover content
        type: String,
        value: ''
      },
      bulkCheckboxSelector: {
        // selector for the check/uncheck all checkbox if used
        type: String,
        value: ''
      },
      bulkHiddenInputSelector: {
        // selector for the hidden input to submit all items, even if not shown
        type: String,
        value: ''
      },
      itemCheckboxesSelector: {
        // selector for the checkboxes affected by the bulk checkbox; may be outside this element
        type: String,
        value: ''
      },
      bulkActionsId: {
        // id of the container for actions based on bulk selections; may be outside this element
        type: String,
        value: ''
      },
      nonBulkActionsId: {
        // id of the container for actions not based on bulk selections (toggled with bulk actions)
        type: String,
        value: ''
      },
      hiddenActionsClass: {
        // class that hides either bulkActions or nonBulkActions
        type: String,
        value: 'a11y-hidden'
      },
      selectAllNoticeId: {
        // id of element that contains "all selected" text
        type: String,
        value: ''
      },
      allOnPageSelectedText: {
        // text that says all checkboxes on the page have been checked
        type: String,
        value: 'All {{COUNT}} items on this page have been selected.'
      },
      allSelectedText: {
        // text that says all checkboxes on the page have been checked
        type: String,
        value: 'All {{COUNT}} items have been selected.'
      },
      selectAllText: {
        // text of action to select unloaded results
        type: String,
        value: 'Select all results for current filters'
      },
      selectNoneText: {
        // text of action to unselect unloaded results
        type: String,
        value: 'Clear selections'
      },
      noMoreResultsText: {
        // instead of select-all link when all the items are shown
        type: String,
        value: 'There are no more results.'
      },
      totalItemsCount: {
        // number of items including those not shown, or 0 if total n/a
        type: Number,
        value: 0
      }
    }
  }

  static get is() {
    return 'exceed-table-utils';
  }

  setupClickableRows() {
    if (this.isClickableRows) {
      // NOTE: We're using event delegation here - binding to the tbody and then checking a match on the target.
      // The reason is to allow for dynamic tables - i.e. where rows are added via JavaScript in some way (e.g. infinite scroll)
      this.querySelector('tbody').addEventListener('click', () => {
        const clickedEl = event.target.closest('td');

        if (clickedEl && !clickedEl.getAttribute('data-block-row-click')) {
          const parentRowEl = clickedEl.closest('tr');
          if (parentRowEl) {
            const rowHref = parentRowEl.getAttribute('data-href');
            if (rowHref) {
              location.href = rowHref;
            }
          }
        }
      });
    }
  }

  setupRowHovers() {
    this.querySelectorAll(this.hoverContentSelector).forEach((hoverContentCell) => {
      if (!hoverContentCell.hasAttribute('data-intelluminitialized')) {
        let parentRowEl = hoverContentCell.closest('tr');
        if (parentRowEl) {
          let hoverContent = hoverContentCell.firstElementChild;
          hoverContent.classList.add(this.hoverContentClass);
          hoverContentCell.setAttribute('data-intelluminitialized', true);
        }
      }
    });
  }

  setupCheckboxes() {
    if (this.bulkCheckboxSelector.length && this.itemCheckboxesSelector.length && this.bulkActionsId.length) {

      this._bulkCheckbox = this.querySelector(this.bulkCheckboxSelector);
      if (this._bulkCheckbox && this._bulkCheckbox.tagName != "INPUT") {
        this._bulkCheckbox = this._bulkCheckbox.querySelector('input[type="checkbox"]');
      }
      this._itemCheckboxes = this.querySelectorAll(this.itemCheckboxesSelector);
      this._bulkActions = document.getElementById(this.bulkActionsId);

      if (this.nonBulkActionsId.length) {
        this._nonBulkActions = document.getElementById(this.nonBulkActionsId);
      }
      if (this._bulkCheckbox && this._itemCheckboxes) {
        this.bindCheckboxHandlers();

        if (this.selectAllNoticeId.length) {
          this._selectAllNotice = document.getElementById(this.selectAllNoticeId);
          if (this._selectAllNotice) {
            if (this.bulkHiddenInputSelector.length) {
              this._bulkHiddenInput = document.querySelector(this.bulkHiddenInputSelector);
              if (this._bulkHiddenInput) {
                this._selectAllAction = document.createElement('button');
                this._selectAllAction.setAttribute('type', 'button');
                this._selectAllAction.classList.add('button', 'button--link');
                this._selectAllAction.innerHTML = (this.totalItemsCount > 0) ? this.selectAllText.replace('{{COUNT}}', this.totalItemsCount) : this.selectAllText.replace('{{COUNT}}', '');

                this._selectNoneAction = document.createElement('button');
                this._selectNoneAction.setAttribute('type', 'button');
                this._selectNoneAction.classList.add('button', 'button--link');
                this._selectNoneAction.innerHTML = this.selectNoneText;

                this.bindSelectButtonActions();
              }
            }
          }
        }

      // Check checkbox statuses after reload or refresh
      this.handleBulkCheckboxEffects();
      }
    }

  }

  handleBulkCheckboxEffects() {
    this._itemCheckboxes.forEach((checkbox) => {
      checkbox.checked = this._bulkCheckbox.checked;
    });
    this.handleItemCheckboxEffects();
  }

  handleItemCheckboxEffects() {
    let isAnyUnchecked = this.querySelector(`${this.itemCheckboxesSelector}:not(:checked)`);
    let checkedCheckboxes = this.querySelectorAll(`${this.itemCheckboxesSelector}:checked`);
    if (isAnyUnchecked) {
      this._bulkCheckbox.checked = false;
    }
    if (checkedCheckboxes.length) {
      this._bulkActions.classList.remove(this.hiddenActionsClass);
      if (this._nonBulkActions) {
        this._nonBulkActions.classList.add(this.hiddenActionsClass);
      }
    } else {
      this._bulkActions.classList.add(this.hiddenActionsClass);
      if (this._nonBulkActions) {
        this._nonBulkActions.classList.remove(this.hiddenActionsClass);
      }
    }

    this._checkedItemsCount = checkedCheckboxes.length;
    if (this._selectAllNotice) {
      if (isAnyUnchecked) {
        this._selectAllNotice.innerHTML = '';
      } else {
        this._selectAllNotice.innerHTML = this.allOnPageSelectedText.replace('{{COUNT}}', this._checkedItemsCount);
        if (this._selectAllAction) {
          if (this.totalItemsCount > 1 && this._checkedItemsCount < this.totalItemsCount) {
            this._selectAllNotice.innerHTML += ' ';
            this._selectAllNotice.append(this._selectAllAction);
          } else {
            this._selectAllNotice.innerHTML += ` ${this.noMoreResultsText}`;
          }
        }
      }
    }
  }

  handleActionsOnDisconnect() {
    if (this._bulkActions) {
      this._bulkActions.classList.add(this.hiddenActionsClass);
    }
    if (this._nonBulkActions) {
      this._nonBulkActions.classList.remove(this.hiddenActionsClass);
    }
  }

  handleSelectUndisplayedActions(doSelectAll=false) {
    if (doSelectAll) {
      this._bulkHiddenInput.setAttribute('value', 'all');
      this._selectAllNotice.removeChild(this._selectAllAction);
      this._selectAllNotice.innerHTML = `${this.allSelectedText.replace('{{COUNT}}', this.totalItemsCount)} `;
      this._selectAllNotice.append(this._selectNoneAction);
    } else {
      this._bulkHiddenInput.setAttribute('value', '');
      this._bulkCheckbox.checked = false;
      this.handleBulkCheckboxEffects();
    }
  }

  bindCheckboxHandlers() {
    this._boundBulkCheckboxClickHandler = this.handleBulkCheckboxEffects.bind(this);
    this._boundItemCheckboxClickHandler = this.handleItemCheckboxEffects.bind(this);
    this._bulkCheckbox.addEventListener('click', this._boundBulkCheckboxClickHandler);
    this._itemCheckboxes.forEach((checkbox) => {
      checkbox.addEventListener('click', this._boundItemCheckboxClickHandler);
    });
  }

  bindSelectButtonActions() {
    this._boundSelectAllActionHandler = this.handleSelectUndisplayedActions.bind(this, true);
    this._selectAllAction.addEventListener('click', this._boundSelectAllActionHandler);
    this._boundSelectNoneActionHandler = this.handleSelectUndisplayedActions.bind(this, false);
    this._selectNoneAction.addEventListener('click', this._boundSelectNoneActionHandler);
  }

  unbindCheckboxHandlers() {
    if (this._bulkCheckbox && this._boundBulkCheckboxClickHandler) {
      this._bulkCheckbox.removeEventListener('click', this._boundBulkCheckboxClickHandler);
    }
    if (this._itemCheckboxes && this._boundItemCheckboxClickHandler) {
      this._itemCheckboxes.forEach((checkbox) => {
        checkbox.removeEventListener('click', this._boundItemCheckboxClickHandler);
      });
    }
  }

  unbindSelectButtonActions() {
    if (this._boundSelectAllActionHandler && this._boundSelectNoneActionHandler) {
      this._selectAllAction.removeEventListener('click', this._boundSelectAllActionHandler);
      this._selectNoneAction.removeEventListener('click', this._boundSelectNoneActionHandler);
    }
  }

  init() {
    this.setupClickableRows();
    this.setupRowHovers();
    this.setupCheckboxes();

    this._subscribeToLoaded = PubSub.subscribe(pubSubEvents.infinitescroll.loaded, () => {
      this.setupRowHovers();
      this.setupCheckboxes();
    })
  }

  connectedCallback() {
    super.connectedCallback();
    this.init();
  }

  disconnectedCallback() {
    super.connectedCallback();
    PubSub.unsubscribe(this._subscribeToLoaded);
    this.handleActionsOnDisconnect();
    this.unbindCheckboxHandlers();
    this.unbindSelectButtonActions();
  }
}

customElements.define('exceed-table-utils', ExceedTableUtils);

/*
Component: <exceed-set-field>

Description:
When you click on this element it will populate one field with the element's text content and another with a value.

Optionally, it can give the element a css class to mark its selection.

*/

import { PolymerElement } from '@polymer/polymer';

class ExceedSetField extends PolymerElement {
  static get is() {
    return 'exceed-set-field';
  }


  static get properties() {
    return {
      targetTextFieldId: {
        type: String
      },
      targetValueFieldId: {
        type: String
      },
      itemValue: {
        type: String
      },
      selectableElSelector: {
        // Unique css selector for element within that could be marked selected
        // - or might be currently marked selected in a sibling <set-field>
        type: String,
        value: ''
      },
      selectedClass: {
        // Class to add to the designated element within to mark selection
        type: String,
        value: ''
      },
      itemText: {
        // Define text to copy if the element copy doesn't
        type: String,
        value: ''
      },
      allowEventDefault: {
        // Don't `event.preventDefault` - let what happens inside happen
        // (needed if this element encloses a checkbox, so it will respond to a click)
        type: Boolean,
        value: false
      }
    }
  }

  doSetValues() {
    this.addEventListener('click', (event) => {
      if (!this.allowEventDefault) {
        event.preventDefault();
      }

      let targetValueEl = document.getElementById(this.targetValueFieldId);
      let targetTextEl = document.getElementById(this.targetTextFieldId);

      if (this.targetValueFieldId && targetValueEl) {
        targetValueEl.value = this.itemValue;
      }
      if (this.targetTextFieldId && targetTextEl) {
        targetTextEl.value = this.itemText || this.innerText;
      }
      if (this.selectedClass.length && this.selectableElSelector.length) {
        // Find all like selectable elements and remove any existing selectedClass
        let selectableEls = document.querySelectorAll(this.selectableElSelector);
        selectableEls.forEach((el) => {
          el.classList.remove(this.selectedClass);
        });
        // Add selectedClass to this container's selectable element
        let newSelectedEl = this.querySelector(this.selectableElSelector);
        if (newSelectedEl) {
          newSelectedEl.classList.add(this.selectedClass);
        }
      }
    });
  }

  connectedCallback() {
    super.connectedCallback();
    this.doSetValues();
  }
}

customElements.define('exceed-set-field', ExceedSetField);

/*
Component: <exceed-approve-item>
Usage:
Notes:
*/

import { PolymerElement } from '@polymer/polymer';
import axios from '../util/axios'
import PubSub from "pubsub-js";
import pubSubEvents from "../util/pubsub_event_channels";

class ExceedApproveItem extends PolymerElement {

  static get is() {
    return 'exceed-approve-item';
  }

  static get properties() {
    return {
      itemId: {
        type: String,
        value: ''
      },
      confirmDialogId: {
        type: String,
        value: ''
      },
      confirmButtonId: {
        type: String,
        value: ''
      },
      triggerSelector: {
        type: String,
        value: 'button'
      },
      approveItemEndpoint: {
        type: String,
        value: ''
      },
      approveItemMethod: {
        type: String,
        value: 'put'
      },
      approveSuccessMessage: {
        type: String,
        value: ''
      },
      approveErrorMessage: {
        type: String,
        value: ''
      },
      isSendingMessage: {
        type: Boolean,
        value: false
      },
      approvingItemClass: {
        type: String,
        value: ''
      },
      reloadFormIfEmptied: {
        // reload the remote form if the last item in a list is approved (and the remote form exists)
        type: Boolean,
        value: false
      },
      parentFormSelector: {
        // if replacing the parent form, use this selector to find the element to replace
        type: String,
        value: 'exceed-magic-form'
      }
    }
  }

  showFeedback(isSuccess, errorMessage) {
    // TODO: We need to find a nicer way to do this. Not good having an external dependency.
    // We could use a web component for it maybe... A message bus might also help. Not sure.
    if (!window.Intellum || !Intellum.flashnotice) {
      return;
    }
    errorMessage = errorMessage || this.approveErrorMessage;
    if (isSuccess && this.approveSuccessMessage) {
      Intellum.flashnotice.show(this.approveSuccessMessage);
    } else if (!isSuccess && errorMessage) {
      Intellum.flashnotice.show(errorMessage, 'warning');
    }
  }

  publishApprovedEvent() {
    PubSub.publish(pubSubEvents.approve_success, {
      itemId: this.itemId
    });
  }

  setApprovingClass(itemEl, isApproving) {
    if (!this.approvingItemClass) {
      return false;
    }

    if (isApproving) {
      itemEl.classList.add(this.approvingItemClass);
    } else {
      itemEl.classList.remove(this.approvingItemClass);
    }
  }

  removeElement(itemEl) {
    let approveButtom = itemEl.getElementsByTagName('exceed-approve-item')[0];
    if (approveButtom) { approveButtom.remove(); }
  }

  approveItem() {
    let itemEl = document.getElementById(this.itemId);
    let parentEl = itemEl.parentNode;

    if (!itemEl || this._isApproving) {
      // do nothing if no linked item is found or if we're already approving
      return false;
    }

    // set this so that we don't try to delete multiple times
    this._isApproving = true;

    // send an event to indicate that we have started approving
    PubSub.publish(pubSubEvents.approve_start, {
      itemId: this.itemId
    });

    // add a approving class if there is one
    this.setApprovingClass(itemEl, true);

    axios[this.approveItemMethod](this.approveItemEndpoint)
      .then((response) => {
        this.removeElement(itemEl);
        this.setApprovingClass(itemEl, false);
        this.showFeedback(true);
      })
      .catch((error) => {
        // revert
        this.setApprovingClass(itemEl);
        this._isApproving = false;
        // show feedback
        if (error.response && error.response.data && error.response.data.errors) {
          this.showFeedback(false, error.response.data.errors[0]);
        }
      });
  }

  startApprove() {
    this.approveItem();
  }

  triggerListener() {
    this.triggerEl.blur();
    this.startApprove();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    // approve listeners when the item is approved
    this.triggerEl.removeEventListener('click', this._boundTriggerListener);
  }

  connectedCallback() {
    super.connectedCallback();
    this.triggerEl = this.querySelector(this.triggerSelector);

    if (this.triggerEl) {
      this.triggerEl.addEventListener('click', this._boundTriggerListener);
    }
  }

  constructor() {
    super();

    // bind event listeners internally so a) we can unbind and b) so that `this` remains as a reference to the component
    this._boundTriggerListener = this.triggerListener.bind(this);
  }
}

customElements.define('exceed-approve-item', ExceedApproveItem);

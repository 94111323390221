/*
Component: <exceed-modal-helper>

Usage:

This component should be rendered to the DOM only once (at the end of the layout)

It controls modals within the page.

Specifically:

* It creates XHR modals in response to a trigger
  * by fetching the modal content and inserting it into the page
* It loads modals via the url on page load

Events:

* PubSub
  * Subscribes to:
    * `modal.open` to fetch content and open (if the event data contains a url and the modal isn't already in dom)
* page load
  * Opens the hash exactly matches the trigger-target id of a trigger on the page
*/

import { PolymerElement } from '@polymer/polymer';
import PubSub from 'pubsub-js';
import axios from 'axios';

import pubSubEvents from '../util/pubsub_event_channels';
import getElementFromRawHtml from '../util/get_element_from_raw_html';

class ExceedModalHelper extends PolymerElement {

  static get properties() {
    return {
      modalElementName: {
        // the element name for the modals that we're "helping"
        type: String,
        value: 'exceed-modal'
      }
    }
  }

  static get is() {
    return 'exceed-modal-helper';
  }

  /**
   * Determines whether we need to create a new modal and insert it into the page
   * - Is there are url in the event?
   * - Is there already a modal with this id on the page (if so it can look after itself)
   * */
  isNewXHRModalNeeded(eventData) {
    // we need a url
    // we shouldn't do anything if there is a modal element with a matching id already on the page
    return eventData && eventData.url && !(eventData.triggerTarget && document.getElementById(eventData.triggerTarget));
  }

  /**
   * Create a new modal inside `this` helper.
   * Assumes that the modal will be a direct child node (it should always be - and it should also be the first one
   * but sometimes #text gets in the way).
   * Create the modal node and then call it's own open method. Now it can look after itself.
   * */
  createNewModal(modalContentsHtml, url, modalContainerId) {
    let newModal;
    const docFragment = document.createDocumentFragment();
    const divEl = document.createElement('div');
    let modalContainerElement = document.getElementById(modalContainerId);

    divEl.innerHTML = modalContentsHtml;

    for (let childNode of Array.from(divEl.childNodes)) {
      if (childNode.nodeName.toLowerCase() === this.modalElementName) {
        docFragment.appendChild(childNode);
        break;
      }
    }

    newModal = docFragment.firstChild;
    newModal.setAttribute('update-url', url);

    if (modalContainerId && modalContainerElement) {
      modalContainerElement.appendChild(newModal);
    } else {
      this.appendChild(newModal);
    }
    newModal.openModal();
    this.reinitializeLegacyBindings();
  }

  /**
   * Any legacy JS in the XHR loaded content _may_ need to be reinitialized.
   */
  reinitializeLegacyBindings() {
    if (window.Intellum && Intellum.util && Intellum.util.reinitialize) {
      Intellum.util.reinitialize.trigger();
    }
  }

  /**
   * Update the content of an existing modal via xhr using the updateUrl property of the modal
   * */
  updateModalContent(modalData) {
    let modalToUpdate = this.querySelector('#' + modalData.triggerTarget);
    if (modalToUpdate && modalToUpdate.updateUrl) {
      axios.get(modalToUpdate.updateUrl)
        .then((response) => {
          let elementToReplace = modalToUpdate.querySelector('.modal__content');
          elementToReplace.parentElement.replaceChild(getElementFromRawHtml(response.data, '.modal__content'), elementToReplace);

          // Close the modal that triggered the update, if there is such a thing
          if (modalData.triggerSource) {
            let triggerModal = this.querySelector('#' + modalData.triggerSource);
            if (triggerModal) {
              window.setTimeout(() => {
                PubSub.publish(pubSubEvents.modal_destroy, {"triggerTarget": modalData.triggerSource});
              });
            }
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    } else if (modalData.triggerSource) {
      // No url to update, but we still might need to close a source modal
      let triggerModal = this.querySelector('#' + modalData.triggerSource);
      if (triggerModal) {
        PubSub.publish(pubSubEvents.modal_destroy, {"triggerTarget": modalData.triggerSource});
      }
    }
  }

  /**
   * Load the modal content from the url in the event data and then create it
   * */
  loadModalContentAndCreate(modalData) {
    axios.get(modalData.url)
      .then((response) => {
        this.createNewModal(response.data, modalData.url, modalData.modalContainerId);
      })
      .catch((error) => {
        //console.log(error);
      });
  }

  /**
   * Listen to the event bus for "modal.open" events and give a helping hand for modals that need to be created
   * */
  listenToEventBus() {
    PubSub.subscribe(pubSubEvents.modal_open, (msg, eventData) => {
      if (this.isNewXHRModalNeeded(eventData)) {
        this.loadModalContentAndCreate(eventData);
      }
    });
    PubSub.subscribe(pubSubEvents.modal_update, (msg, eventData) => {
      this.updateModalContent(eventData);
    });
  }

  /**
   * On the page load, check if there's a hash. If there is AND there's a trigger for that id, click the trigger
   * to cause the modal to open.
   *
   * Note: At the moment we're using a click on the trigger to avoid parsing data out of the trigger (let the
   * trigger's connection to the event bus do that)
   *
   * This means that a modal can't be opened via URL with hash if there is no existing
   * trigger button for it on the url page. This rules out using a url to go directly
   * to a second-level modal.
   * */
  openModalFromUrl() {
    const dialogIdFromHash = window.location.hash.substring(1);
    let matchingModalTriggerEl;

    if (dialogIdFromHash && !document.getElementById(dialogIdFromHash)) {
      matchingModalTriggerEl = document.querySelector(`[data-trigger-target="${dialogIdFromHash}"]`);

      if (matchingModalTriggerEl) {
        matchingModalTriggerEl.click();
      }
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.listenToEventBus();
    this.openModalFromUrl();
  }
}

customElements.define('exceed-modal-helper', ExceedModalHelper);

/**
 * Gets an element from raw html with the following assumptions:
 * - We're only interested in 1 element
 *
 * Primarily this is useful for parsing out the useless text nodes that you get in a html response.
 * But is also returns an actual node that you can use `.appendChild` for instead of just replacing the
 * whole element contents with .innerHTML
 * */
export default (rawHtml, elementSelector) => {
  const docFragment = document.createDocumentFragment();
  const divEl = document.createElement('div');

  divEl.innerHTML = rawHtml;
  docFragment.appendChild(divEl);

  return docFragment.querySelector(elementSelector);
};
/*
Component: <exceed-filter-content>
Usage:  Used in conjunction with other `exceed-filter-*` elements to manage filtering of content.
Notes:
 * This element manages the filtering of content by replacing contents with the results retrieved from an XHR endpoint.

*/

import { PolymerElement } from '@polymer/polymer';
import queryString from 'query-string';
import PubSub from 'pubsub-js';
import axios from 'axios';

import pubSubEvents from '../util/pubsub_event_channels';

const fadeTransitionClass = 'filtercontent--fadetransition';
const fadedInClass = 'filtercontent--fadedin';

export default class ExceedFilterContent extends PolymerElement {

  static get is() {
    return 'exceed-filter-content';
  }

  static get properties() {
    return {
      currentFilters: {
        // store the current filters so they can be reapplied if new content added
        type: Object,
        value: {}
      },
      xhrUrl: {
        // the base endpoint for XHR updates
        type: String,
        value: ''
      },
      xhrErrorText: {
        // Error text to use if the search fails
        type: String,
        value: 'The search was not successful.'
      },
      counterSelector: {
        // selector for finding counter text in the XHR response
        type: String,
        value: '[data-counter-text]'
      },
      applyFiltersOnLoad: {
        // if true we will apply any filters when the page loads (for if filter are not applied at server)
        type: Boolean,
        value: false
      }
    }
  }

  /**
   * Apply the filters (derived from the url) by loading content via XHR
   * */
  applyFilters() {
    // We want to compare the url's search string to any previous one
    // and only apply filters if they're different
    // This prevents content from reloading if a modal is opened and changes the url hash
    let newSearch = window.location.search.substring(1);
    if (newSearch === this._previousSearch) {
      window.dispatchEvent(new Event('filterContent.loaded'));
      return;
    }
    this.currentFilters = queryString.parse(location.search, {arrayFormat: 'bracket'});

    if (this.xhrUrl.length && this.currentFilters) {
      this.applyFadeOut();
      axios.get(this.xhrUrl, { params: this.currentFilters })
        .then((response) => {
          this.innerHTML = response.data;
          this.sendUpdateCounterEvent();
          this.applyFadeIn();
          if (Intellum && Intellum.lazyload) {
            Intellum.lazyload.observe()
          }
        })
        .catch(() => {
          this.innerHTML = `<p>${this.xhrErrorText}</p>`;
        })
        .finally(() => {
          window.dispatchEvent(new Event('filterContent.loaded'));
          // Update the search-string holder
          this._previousSearch = newSearch;
        });
    }
  }

  /**
   * Publish an event so that a counter can update
   * (new counter text is in the event data)
   * Keep this as a separate method so it can be overridden if this element is extended
   * */
  sendUpdateCounterEvent() {
    let counterTextEl = this.querySelector(this.counterSelector);
    if (counterTextEl) {
      PubSub.publish(pubSubEvents.filters_counter, {
        counterText: counterTextEl.dataset.counterText,
        counterA11yText: counterTextEl.dataset.counterA11yText
      });
    }
  }

  /**
   * Fade used to smooth out the loading/change lag
   * */
  applyFadeIn() {
    if (this.classList.contains(fadeTransitionClass)) {
      this.classList.add(fadedInClass);
    }
  }

  /**
   * Fade used to smooth out the loading/change lag
   * */
  applyFadeOut() {
    if (this.classList.contains(fadeTransitionClass)) {
      this.classList.remove(fadedInClass);
    }
  }

  /**
   * Apply filters whenever we receive an update event
   * Also apply filters on history pop (back links)
   * */
  bindToEvents() {
    PubSub.subscribe(pubSubEvents.filters_update, (msg, eventDetail) => {
      if (!this.applyFiltersOnLoad && eventDetail && eventDetail.isOnPageLoad) {
        // if we're not meant to apply filters on load AND the event comes from a page load, do nothing
        this._previousSearch = window.location.search.substring(1);
        return false;
      }
      this.applyFilters(eventDetail);
    });

    window.addEventListener('popstate', () => {
      this.applyFilters();
    });
  }

  /**
   * If the server-rendered page doesn't already filter the content we can apply the filters on load.
   * (if we're not applying the filters we may still need to manage the fade)
   * */
  handleInitialFiltering() {
    if (this.applyFiltersOnLoad) {
      this.applyFilters();
    } else {
      // Record the initial search string so next applyFilters can check it
      this._previousSearch = window.location.search.substring(1);
      this.applyFadeIn();
    }
  }

  /**
   * INIT
   * */
  connectedCallback() {
    super.connectedCallback();
    this.bindToEvents();
    this.handleInitialFiltering();
  }
}

customElements.define('exceed-filter-content', ExceedFilterContent);

/**
 * Triggers can fire Event Bus events (for example to open a modal).
 *
 * They work by applying data attributes - typically to a button - using the following conventions:
 *
 * - `data-trigger`:          The name of the event that is triggered
 * - `data-trigger-target`:   The id of the element that this event should act on
 * - `data-trigger-data`:     Any additional data you want to pass into the event (to be used by a component)
 *
 * Example:
 *
 * ```
 * <button class="button" data-trigger="modal.open" data-trigger-target="login_modal"
 *         data-trigger-data='{"url": "/some/path"}'>
 *   Launch a modal
 * </button>
 * ```
 *
 * Notes:
 *
 * - the data in `data-trigger-data` will need to be in strict JSON format (take note of the quotes).
 *
 * IMPORTANT: PLEASE TRY NOT TO ADD ADDITIONAL EVENT LISTENERS TO THE BODY ELEMENT.
 * - ideally we would listen only for "click" events here (on buttons and links) and then use the `data-trigger` to
 *   match those triggers to corresponding handlers.
 * - for other event types, please consider using event delegation within a limited scope instead (e.g. in a custom
 *   element surrounding the elements that might need to trigger something).
 *
 * */

import PubSub from 'pubsub-js';

const handleEvent = function(event) {
  let eventData = {};

  /* TODO: This is used sometimes to get around the firing of the click event when the Enter key is pressed. We need to find a more global way to fix that,
  especially regarding launching modals. */
  if (event.target.dataset.preventDefault) {
    event.preventDefault();
  }

  // add any data (from JSON in `data-trigger-data` on the target el)
  try {
    eventData = JSON.parse(event.target.dataset.triggerData);
  } catch(error) {
    eventData = {}
  }

  // add a target for the trigger to act on (from `data-trigger-target` on the target el)
  eventData.triggerTarget = event.target.dataset.triggerTarget;

  // add the value of the element to the eventData
  eventData.triggerValue = event.target.value;

  // publish the event
  PubSub.publish(event.target.dataset.trigger, eventData);
};

// CLICK AND ENTER EVENTS

document.body.addEventListener('click', (event) => {
  if (event.target.matches(`[data-trigger]`) && !event.target.matches(`[data-trigger-event]`) && event.target.dataset.trigger) {
    handleEvent(event);
  }
});
document.body.addEventListener('keydown', (event) => {
  if (event.key === 'Enter' && event.target.matches(`[data-trigger]`) && !event.target.matches(`[data-trigger-event]`) && event.target.dataset.trigger) {
    handleEvent(event);
  }
});


// INPUT EVENTS

const isMatchingInputTriggerEvent = function(event, listener) {
  return event.target && event.target.matches(`[data-trigger-event]`) && event.target.dataset.trigger && event.target.dataset.triggerEvent === listener;
};

document.body.addEventListener('input', (event) => {
  if (isMatchingInputTriggerEvent(event,'input')) {
    handleEvent(event);
  }
});

document.body.addEventListener('change', (event) => {
  if (isMatchingInputTriggerEvent(event,'change')) {
    handleEvent(event);
  }
});

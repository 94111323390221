export default {
  // Turns '#fcfcfc' to [252, 252, 252]
  rgbArrayFromHexValue: (hexValue) => {
    if (hexValue.length == 4) {
      let hexValueArray = hexValue.substring(1).split();
      hexValue = '#';
      hexValueArray.forEach((element) => {
        hexValue += element + element;
      });
    }
    return hexValue.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i,(m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1).match(/.{2}/g)
    .map(x => parseInt(x, 16));
  },

  // Turns 'rgb(252, 252, 252)' to ["252", "252", "252)"]
  rgbArrayFromRgbValue: (rgbValue) => {
    return rgbValue.substring(4).split(', ');
  },

  // Tests either of above to see if the color value is nearly white
  isNearlyWhiteColor: (rgbArray) => {
    /* Minimum value for r, b, and g to indicate that color is close enough
       to white to treat like white */
    let rgbNearlyWhite = 252;

    for (var i = 0; i < 3; i++) {
      if (parseInt(rgbArray[i]) < rgbNearlyWhite) {
        return false;
      }
    }
    return true;
  }
}

/*
Component: <exceed-remove-item>
Usage:
Notes:
*/

import { PolymerElement } from '@polymer/polymer';
import axios from '../util/axios'
import PubSub from "pubsub-js";
import pubSubEvents from "../util/pubsub_event_channels";

class ExceedRemoveItem extends PolymerElement {

  static get is() {
    return 'exceed-remove-item';
  }

  static get properties() {
    return {
      itemId: {
        type: String,
        value: ''
      },
      confirmDialogId: {
        type: String,
        value: ''
      },
      confirmButtonId: {
        type: String,
        value: ''
      },
      triggerSelector: {
        type: String,
        value: 'button'
      },
      removeItemEndpoint: {
        type: String,
        value: ''
      },
      removeItemMethod: {
        type: String,
        value: 'delete'
      },
      removeSuccessMessage: {
        type: String,
        value: ''
      },
      removeErrorMessage: {
        type: String,
        value: ''
      },
      isSendingMessage: {
        type: Boolean,
        value: false
      },
      removingItemClass: {
        type: String,
        value: ''
      },
      reloadFormIfEmptied: {
        // reload the remote form if the last item in a list is removed (and the remote form exists)
        type: Boolean,
        value: false
      },
      parentFormSelector: {
        // if replacing the parent form, use this selector to find the element to replace
        type: String,
        value: 'exceed-magic-form'
      },
      isUndoingRenderInPlace: {
        // if true, move rendered-in-place dialog to document body
        type: Boolean,
        value: false
      }
    }
  }

  showFeedback(isSuccess, errorMessage) {
    // TODO: We need to find a nicer way to do this. Not good having an external dependency.
    // We could use a web component for it maybe... A message bus might also help. Not sure.
    if (!window.Intellum || !Intellum.flashnotice) {
      return;
    }
    errorMessage = errorMessage || this.removeErrorMessage;
    if (isSuccess && this.removeSuccessMessage) {
      Intellum.flashnotice.show(this.removeSuccessMessage);
    } else if (!isSuccess && errorMessage) {
      Intellum.flashnotice.show(errorMessage, 'warning');
    }
  }

  sendMessage(parentEl) {
    if (this.isSendingMessage) {
      // The data object can be expanded with other keys for other message needs;
      // The listening element would look for what it needs based on the key
      PubSub.publish(pubSubEvents.update_content, {
        source: parentEl.id,
        count: parentEl.childElementCount.toString()
      });
    }
  }

  publishRemovedEvent() {
    PubSub.publish(pubSubEvents.remove_success, {
      itemId: this.itemId
    });
  }

  setRemovingClass(itemEl, isRemoving) {
    if (!this.removingItemClass) {
      return false;
    }

    if (isRemoving) {
      itemEl.classList.add(this.removingItemClass);
    } else {
      itemEl.classList.remove(this.removingItemClass);
    }
  }

  /**
      * In some cases, where the item being removed is part of a list, we'll need to reload the form that the list
      * lives in so that we can show the "no results" message and corresponding UI.
   * */
  reloadFormIfLastItemRemoved(parentEl, newFormHTML) {
    if (this.reloadFormIfEmptied && !parentEl.innerHTML.trim() && newFormHTML) {
      let parentForm = parentEl.closest(this.parentFormSelector) || parentEl.closest('form[data-remote="true"]');
    if (parentForm) {
        parentForm.innerHTML = newFormHTML;
      }
    }
  }

  removeItem(eventData) {
    let itemEl = document.getElementById(this.itemId);
    let parentEl = itemEl.parentNode;

    if (!itemEl || this._isDeleting) {
      // do nothing if no linked item is found or if we're already deleting
      return false;
    }

    // set this so that we don't try to delete multiple times
    this._isDeleting = true;

    // send an event to indicate that we have started removing
    PubSub.publish(pubSubEvents.remove_start, {
      itemId: this.itemId
    });

    // add a removing class if there is one
    this.setRemovingClass(itemEl, true);

    axios[this.removeItemMethod](this.removeItemEndpoint)
      .then((response) => {
        parentEl.removeChild(itemEl);
        this.sendMessage(parentEl);
        this.showFeedback(!(eventData && eventData.isFail));
        this.publishRemovedEvent();
        this.reloadFormIfLastItemRemoved(parentEl, response.data);
      })
      .catch((error) => {
        // revert
        this.setRemovingClass(itemEl);
        this._isDeleting = false;
        // show feedback
        if (error.response && error.response.data && error.response.data.errors) {
          this.showFeedback(false, error.response.data.errors[0]);
        }
      });
  }

  startRemove(eventData) {
    if (this._dialogEl) {
      this._dialogEl.dispatchEvent(new window.CustomEvent('show.dialog', {detail: {trigger: null}}));
      this._confirmButtonEl.addEventListener('click', this._boundConfirmListener);
    } else {
      this.removeItem(eventData);
    }
  }

  triggerListener() {
    this._triggerEl.blur();
    this.startRemove();
  }

  handleRemoveRequestEvents() {
    this._handleRemoveEvents = PubSub.subscribe(pubSubEvents.remove_item, (msg, eventData) => {
      if (eventData.itemId === this.itemId) {
        this.startRemove(eventData);
      }
    });
  }

  confirmListener() {
    if (this._confirmButtonEl && this._dialogEl) {
      this._confirmButtonEl.removeEventListener('click', this._boundConfirmListener);
      this._dialogEl.dispatchEvent(new window.CustomEvent('hide.dialog'));
      this.removeItem();
    }
  }

  handleCancelConfirm() {
    if (this._dialogEl) {
      if (this.isUndoingRenderInPlace && this._dialogEl.parentNode !== document.body) {
        document.body.appendChild(this._dialogEl);
        // re-init dialogs
      }

      this._dialogEl.addEventListener('hide.dialog', () => {
        this._confirmButtonEl.removeEventListener('click', this._boundConfirmListener);
      });
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this._triggerEl = this.querySelector(this.triggerSelector) || document.querySelector(this.triggerSelector);

    // these elements are in the document rather than the custom element so wait for connected callback
    if (this.confirmDialogId) {
      this._dialogEl = document.getElementById(this.confirmDialogId);
      this._confirmButtonEl = document.getElementById(this.confirmButtonId);
      this.handleCancelConfirm();
    }

    if (this._triggerEl) {
      this._triggerEl.addEventListener('click', this._boundTriggerListener);
    }
    this.handleRemoveRequestEvents();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    // remove listeners when the item is removed
    if (this._triggerEl) {
      this._triggerEl.removeEventListener('click', this._boundTriggerListener);
    }
    if (this._confirmButtonEl) {
      this._confirmButtonEl.removeEventListener('click', this._boundConfirmListener);
    }
    PubSub.unsubscribe(this._handleRemoveEvents);
  }

  constructor() {
    super();

    // bind event listeners internally so a) we can unbind and b) so that `this` remains as a reference to the component
    this._boundTriggerListener = this.triggerListener.bind(this);
    this._boundConfirmListener = this.confirmListener.bind(this);
  }
}

customElements.define('exceed-remove-item', ExceedRemoveItem);

/*
Component: <exceed-upload-manager>
Usage:
Notes:

- This works in conjunction with the existing `app/assets/javascripts/default/components/uploadfile.js`
  file (Which uses a jQuery plugin).
- We might eventually be able to tidy up the `uploadfile.js` script and move it here - but at the moment
  that could cause a lot of disruption.

This module:
- listens for a complete event in the uploadfile.js script and then
    - fires a pubsub event so that a preview can be updated
    - saves the uploaded path via a "direct save" XHR POST (if included)
- TODO: instantiates the `uploadfile.js` when an upload is dynamically added to the DOM (as for XHR modals)

Events:

* PubSub
  * Publishes an 'uploadfile.change' event
* Other
  * Listens for a 'uploadfile.complete' event on the nested ".uploadfile" element

*/

import { PolymerElement } from '@polymer/polymer';
import axios from '../util/axios';
import PubSub from 'pubsub-js';

import pubSubEvents from '../util/pubsub_event_channels';

const  STATUS_SAVING = 'saving';
const  STATUS_COMPLETE = 'complete';

class ExceedUploadManager extends PolymerElement {

  static get properties() {
    return {
      autoSavePath: {
        type: String,
        value: ''
      },
      uploadFileControlsSelector: {
        type: String,
        value: '.uploadfile'
      },
      uploadField: {
        // the id of the field that this manager manages
        type: String,
        value: ''
      },
      existingFileInfoSelector: {
        type: String,
        value: '.uploadmanager__filename'
      }
    }
  }

  static get is() {
    return 'exceed-upload-manager';
  }

  showFeedback(message) {
    // TODO: We need to find a nicer way to do this. Not good having an external dependency.
    // We could use a web component for it maybe... A message bus might also help. Not sure.
    if (window.Intellum && Intellum.flashnotice && message) {
      Intellum.flashnotice.show(message);
    }
  }

  /**
   * This allows us to publish an event for the various stages of uploading a file
   * If the process is "complete" we can hide the file name as well
   * */
  sendUploadSuccessEvent(url, status, fileName) {

    PubSub.publish(pubSubEvents.uploadfile_change, {
      uploadField: this.uploadField,
      url: url,
      status: status,
      fileName: fileName,
    });

    if (status === STATUS_COMPLETE) {
      // if file info was present on load, we can hide it now (it will be shown by the file upload ui instead)
      this.hideFileInfo();
    }
  }

  /**
   * Send a delete event so that the preview image can respond.
   * Hide the file info
   * */
  sendUploadRemoveEvent() {
    // reflect the change in the upload ui
    // At this point we're probably best to send the error to the older uploadfile.js
    this.fileUploaderEl.dispatchEvent(new CustomEvent('delete'));

    // and publish a pubsub event so that the preview image can sort itself out
    PubSub.publish(pubSubEvents.uploadfile_delete, {
      uploadField: this.uploadField
    });

    // and if we're showing file info, hide it
    this.hideFileInfo();
  }

  /**
   * Send a start event so that other components can respond.
   * */
  sendUploadStartEvent() {
    PubSub.publish(pubSubEvents.uploadfile_start, {
      uploadField: this.uploadField
    });
  }

  /**
   * Handle an error from the auto save - or rather, send some events so that others can deal with it
   * */
  sendAutoSaveErrorEvent(isRemoveError) {
    if (isRemoveError) {
      // if removing has failed, it doesn't make sense to keep showing the upload controls...
      this.fileUploaderEl.innerHTML = `<span class="uploadfile__status uploadfile__error">${this.dataset.removeErrorMsg}</span>`;
    } else {
      // show a message to the user
      // At this point we're probably best to send the error to the older uploadfile.js
      this.fileUploaderEl.dispatchEvent(new CustomEvent('error'));
    }

    // also send a pubsub event (so that the preview image can sort itself out)
    PubSub.publish(pubSubEvents.uploadfile_error, {
      uploadField: this.uploadField
    });

    // restore the file info if we're showing it
    if (this.fileInfoEl) {
      this.fileInfoEl.innerHTML = this.cachedFileInfo;
      this.fileInfoEl.hidden = false;
    }
  }

  /**
   * Hides the file info (when there is no preview we show a file name instead)
   * */
  hideFileInfo() {
    if (this.fileInfoEl) {
      this.fileInfoEl.hidden = true;
    }
  }

  /**
   * Build an auto save endpoint, post to it and deal with the response.
   * */
  autoSave(uploadedFilePath) {
    let encodedFilePath = encodeURIComponent(uploadedFilePath);
    let endpoint = `${this.autoSavePath}/?file_url=${encodedFilePath}`;

    axios.post(endpoint)
      .then((response) => {
        // update the preview with our processed file
        if (response.data.status === 'processing') {
          PubSub.publish(pubSubEvents.uploadfile_processing, { processing_url: response.data.processing_url });
        }
        this.sendUploadSuccessEvent(response.data.url, STATUS_COMPLETE);
        this.showFeedback(response.data.message);
      })
      .catch(() => {
        this.sendAutoSaveErrorEvent();
      });
  }

  /**
   * Send a DELETE to the endpoint for removing (and deal with the response)
   * */
  autoRemove() {
    axios.delete(this.autoSavePath)
      .then((response) => {
        this.sendUploadRemoveEvent();
        this.showFeedback(response.data.message);
      })
      .catch(() => {
        this.sendAutoSaveErrorEvent(true);
      });
  }

  /**
   * bound to the jQuery complete event from the `.uploadfile.js` javascript...
   * - auto saves if there is an auto-save-path
   * - sends an upload event so that the uploaded image can respond (and show a preview)
   * */
  handleUploadCompleteEvent(event) {
    let uploadedFilePath = event.detail.path;

    // then auto-save the image
    if (this.autoSavePath && uploadedFilePath) {
      this.sendUploadSuccessEvent(uploadedFilePath, STATUS_SAVING);
      this.autoSave(uploadedFilePath);
    } else {
      this.sendUploadSuccessEvent(uploadedFilePath, STATUS_COMPLETE, event.detail.fileName);
    }
  }

  /**
   * bound to the jQuery remove event from the `.uploadfile.js` javascript...
   * - auto deletes if there is an auto-save-path
   * - sends an upload event so that the uploaded image can respond (and show a preview)
   * */
  handleUploadRemoveEvent () {
    // then auto-save the image
    if (this.autoSavePath) {
      this.sendUploadSuccessEvent('', STATUS_SAVING);
      this.autoRemove();
    } else {
      this.sendUploadRemoveEvent();
    }
  }

  /**
   * bound to the jQuery start event from the `.uploadfile.js` javascript...
   * - sends an upload event so that the other components can respond
   * */
  handleUploadStartEvent () {
    this.sendUploadStartEvent();
  }

  /**
   * bound to the form_revert PubSub event ...
   * - restores remove/replace choices if changes were canceled
   * */
  handleFormRevertEvent () {
    this.fileUploaderEl.dispatchEvent(new CustomEvent('revert', {
      detail: {initialImageState: this.initialImageState}
    }));
  }

    /**
   * Sets a file info el (and caches its contents). Used if we show the file name instead of a managed preview image.
   * */
  setupFileInfo() {
    this.fileInfoEl = this.querySelector(this.existingFileInfoSelector);
    if (this.fileInfoEl) {
      this.cachedFileInfo = this.fileInfoEl.innerHTML;
    }
  }

  /**
   * If the file uploader has been loaded via XHR we need to make sure that the (older) file upload js is initialised
   * */
  initUploadControls() {
    if (this.fileUploaderEl && !this.fileUploaderEl.dataset.intelluminitialized && window.jQuery) {
      new window.Intellum.uploadFile($(this.fileUploaderEl));
    }
  }

  /**
   * Sets up the elements and binds them
   * */
  init() {
    this.fileUploaderEl = this.querySelector(this.uploadFileControlsSelector);
    this.managedPreviewImage = this.querySelector('exceed-uploaded-image');
    if (this.managedPreviewImage) {
      this.initialImageState = (this.managedPreviewImage.classList.contains('uploadedimage--empty')) ? false : true;
    } else {
      this.initialImageState = false;
    }
    this.initUploadControls();
    this.setupFileInfo();

    this.fileUploaderEl.addEventListener('uploadfile.complete', this._boundHandleUploadCompleteEvent);
    this.fileUploaderEl.addEventListener('uploadfile.removed', this._boundHandleUploadRemoveEvent);
    this.fileUploaderEl.addEventListener('uploadfile.start', this._boundHandleUploadStartEvent);

    PubSub.subscribe(pubSubEvents.form, (msg, eventData) => {
      if (msg === pubSubEvents.form_revert) {
        this.handleFormRevertEvent();
      }
    })
  }

  connectedCallback() {
    super.connectedCallback();
    this.init();
  }

  disconnectedCallback() {
    super.connectedCallback();
    this.fileUploaderEl.removeEventListener('uploadfile.complete', this._boundHandleUploadCompleteEvent);
    this.fileUploaderEl.removeEventListener('uploadfile.removed', this._boundHandleUploadRemoveEvent);
    this.fileUploaderEl.removeEventListener('uploadfile.start', this._boundHandleUploadStartEvent);
    PubSub.unsubscribe(pubSubEvents.form);
  }

  constructor() {
    super();
    this._boundHandleUploadCompleteEvent = this.handleUploadCompleteEvent.bind(this);
    this._boundHandleUploadRemoveEvent = this.handleUploadRemoveEvent.bind(this);
    this._boundHandleUploadStartEvent = this.handleUploadStartEvent.bind(this);
  }
}

customElements.define('exceed-upload-manager', ExceedUploadManager);

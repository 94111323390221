/*
Component: <exceed-filter-content-show-hide>
Usage:  Used in conjunction with other `exceed-filter-*` elements to manage filtering of content.
Notes:
 * This element extends/overrides the `exceed-filter-content` element so that it shows and hides content. Useful for
   cases where there is a smaller number of items on the page.

*/

import ExceedFilterContent from './exceed-filter-content'
import queryString from 'query-string';

export default class ExceedFilterContentShowHide extends ExceedFilterContent {

  static get is() {
    return 'exceed-filter-content-show-hide';
  }

  static get properties() {
    return {
      contentGroupsSelector: {
        // The selector for the items that we show/hide
        type: String,
        value: 'section'
      },
      contentItemsSelector: {
        // The selector for the items that we show/hide
        type: String,
        value: 'li'
      },
      hideItemsClass: {
        // The class we apply to items to hide them
        type: String,
        value: ''
      },
      hideGroupsClass: {
        // The class we apply to items to hide them
        type: String,
        value: ''
      },
      filteredEmptyAttribute: {
        // this attribute gets applied to any item's container if there are no visible items in that container
        // allows for CSS to be used to show messages on the empty state (typically using a data-attribute for the message content)
        type: String,
        value: 'data-filtered-empty'
      },
      attributePrefix: {
        // filtering is done using attributes on the filter items. This is the default prefix for those items
        // e.g. `data-active="true"`
        type: String,
        value: 'data-'
      },
      filteredItemsObservableString: {
        // a string that we can bind an observable too so that we can call a method whenever the filters change
        // (as used by ExceedFilterContentPlan which extends this element)
        type: String,
        value: ''
      }
    }
  }

  /**
   * Determines the items that match the filters
   * */
  matchedElementsForFilter(filterName, filterValues, elements) {
    let matchingItems = [];
    // remove the []'s for array filters - since we're just doing a match on a data attribute
    filterName = decodeURIComponent(filterName).split('[]')[0];
    elements.forEach((element) => {
      let elementFilterAttribute = element.getAttribute(`${this.attributePrefix}${filterName}`);
      let elementValuesForFilter;
      let match;

      if (elementFilterAttribute) {
        elementValuesForFilter = elementFilterAttribute.split(',');
      } else {
        // if the attribute doesn't exist on the element at all it's a no match
        return;
      }

      if (Array.isArray(filterValues)) {
        match = filterValues.find((filterValue) => {
          return elementValuesForFilter.indexOf(filterValue) > -1;
        });
      } else {
        match = elementValuesForFilter.indexOf(filterValues) > -1;
      }
      if (match) {
        matchingItems.push(element);
      }
    });
    return matchingItems;
  }

  /**
   * Toggles the `filteredEmptyAttribute` when filtering leaves items' parent empty
   * */
  toggleEmptyContainerIndicator(itemEl, isEmpty) {
    if (isEmpty) {
      itemEl.parentNode.setAttribute(this.filteredEmptyAttribute, true);
    } else {
      itemEl.parentNode.removeAttribute(this.filteredEmptyAttribute);
    }
  }

  /**
   * Handles the logic for only showing the matched items
   * */
  applyShowHideFilters() {
    let allGroups = this.querySelectorAll(this.contentGroupsSelector);
    let allItems = this.querySelectorAll(this.contentItemsSelector);
    let noResults = this.querySelector('.noresults');
    let matchingItems = [];
    let matchingItemsObservableString = '';
    let filtersToCheck = Object.keys(this.currentFilters);

    // show all groups
    if (allGroups.length && this.hideGroupsClass.length) {
      allGroups.forEach((groupEl) => {
        groupEl.classList.remove(this.hideGroupsClass);
      });
    }

    // hide all
    allItems.forEach((itemEl) => {
      itemEl.classList.add(this.hideItemsClass);
      this.toggleEmptyContainerIndicator(itemEl, true);
    });

    if (filtersToCheck.length) {
      // filter the list (at least one match for each filter)
      filtersToCheck.forEach((filterKey) => {
        let itemsMatchingThisFilter = this.matchedElementsForFilter(filterKey, this.currentFilters[filterKey], allItems);
        matchingItems = matchingItems.concat(itemsMatchingThisFilter);
      });
    } else {
      matchingItems = allItems;
    }

    // if any, show the items that match
    if (Object.keys(matchingItems).length) {
      matchingItems.forEach((itemEl, index) => {
        itemEl.classList.remove(this.hideItemsClass);
        this.toggleEmptyContainerIndicator(itemEl, false);
        matchingItemsObservableString += index + '.';
      });
      if (noResults) {
        noResults.classList.add('noresults--hidden');
      }
    } else {
      if (allGroups.length && this.hideGroupsClass.length) {
        allGroups.forEach((groupEl) => {
          groupEl.classList.add(this.hideGroupsClass);
        });
      }
      if (noResults) {
        noResults.classList.remove('noresults--hidden');
      }
    }

    this.filteredItemsObservableString = matchingItemsObservableString;
  }

  /**
   * Sets `this.currentFilters` by first getting them from the url and then pruning them by removing any params
   * that aren't meant to be applied as filters (via the filter-collector's urlParams attribute which is included as
   * part of the pubsub event data) since we don't want to look for matches for params that aren't really filters
   *
   * Also removes any filters that have a falsey value
   * */
  setCurrentFilters(urlParams) {
    let currentFilters = queryString.parse(location.search);
    urlParams = urlParams || [];

    Object.keys(currentFilters).forEach((filterKey) => {
      if (!currentFilters[filterKey] || urlParams.indexOf(filterKey) > -1) {
        delete currentFilters[filterKey];
      }
    });

    this.currentFilters = currentFilters;
  }

  /**
   * OVERRIDES `applyFilters` in exceed-filter-content
   * By overriding the applyFilters we can do a show/hide filter instead of the XHR
   * */
  applyFilters(eventDetail) {
    eventDetail = eventDetail || {};
    this.applyFadeIn();
    this.setCurrentFilters(eventDetail.urlParams);
    this.applyShowHideFilters();
  }

  /**
   * INIT
   * */
  connectedCallback() {
    super.connectedCallback();
  }
}

customElements.define('exceed-filter-content-show-hide', ExceedFilterContentShowHide);

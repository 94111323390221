/*
Component: <exceed-filter-select>
Usage:  This is an implementation of the custom select element that acts as a filter.
Notes:  The .menulist scss component is designed to be used with this.
*/

import ExceedSimpleSelect from './exceed-simple-select';
import queryString from 'query-string';
import PubSub from 'pubsub-js';

import pubSubEvents from '../util/pubsub_event_channels';

class ExceedFilterSelect extends ExceedSimpleSelect {

  static get is() {
    return 'exceed-filter-select';
  }

  static get properties() {
    return {
      filterName: {
        // the name of the filter/sort parameter
        type: String,
        value: ''
      },
      updateOnLoad: {
        // if this filter is used with a filter collector, set this to true if you wish to send an update to the
        // collector when the page loads
        type: Boolean,
        value: false
      },
      urlParams: {
        // the url parameters that aren't to be applied as filters
        type: Array,
        value: []
      },
      useArrayParams: {
        // set if URL parameters are arrays
        type: Boolean,
        value: false
      },
      inputsToUpdateId: {
        // the id of a container with input (whose name matches filter param name) that needs to be updated when filter is changed
        type: String,
        value: ''
      }
    }
  }

  /**
   * Override the setValue method in `exceed-simple-select` and apply it as a filter to the url
   * */
  setValue(selectedValue, selectedValueText) {
    this.selectedValue = selectedValue;

    // Replace or add param for filterName=filterValue to url
    let params = queryString.parse(location.search, {arrayFormat: 'bracket'});
    let updateUrl;
    if (this.useArrayParams) {
      params[this.filterName] = [selectedValue];
      // build a new update url from the params
      updateUrl = location.href.split('?')[0] + '?' + decodeURIComponent(queryString.stringify(params, {arrayFormat: 'bracket'}));
    } else {
      params[this.filterName] = selectedValue;
      // build a new update url from the params
      updateUrl = location.href.split('?')[0] + '?' + queryString.stringify(params, {arrayFormat: 'bracket'});
    }

    // Update the url with this new one
    history.pushState({ path: updateUrl }, '', updateUrl);

    // Change what's marked as selected in the menu
    this.selectOptions.forEach((option) => {
      if (option.getAttribute(this.optionValueAttribute) == selectedValue) {
        option.setAttribute('aria-selected', 'true');
      } else {
        option.setAttribute('aria-selected', 'false');
      }
    });

    // Change the value of input field (within specified container) whose name is the param name
    // (Needed sometimes to keep search updated with filter changes)
    if (this.inputsToUpdateEl) {
      let inputFieldName = `[name="${this.filterName}"]`;
      let paramInputEls = this.inputsToUpdateEl.querySelectorAll(inputFieldName);
      if (paramInputEls.length) {
        paramInputEls.forEach((inputEl) => {
          inputEl.value = selectedValue;
        });
      }
    }

    // Return focus to trigger
    this.moveFocusToTrigger();

    // Send the update event
    this.sendUpdateEvent();

    // Announce the change of content
    this.setUpdateMessage(selectedValueText);
  }

  /**
   * Send an update event so that the filters know when and how to be applied
   * */
  sendUpdateEvent() {
    PubSub.publish(pubSubEvents.filters_update, {
      // include the list of non-filter url params in case they're needed (as per exceed-filter-content-show-hide)
      urlParams: this.urlParams
    });
  }

  /**
   * Apply the filters when the page loads
   * If the content hasn't already been filtered server-side (as in the case of show/hide filters), we'll need to
   * apply the filters via JS. So this triggers an event to tell the filter-collector to do some filtering.
   * */
  updateFiltersOnLoad() {
    if (this.updateOnLoad) {
      setTimeout(() => {
        this.sendUpdateEvent();
      }, 50);
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.inputsToUpdateEl = document.getElementById(this.inputsToUpdateId);
    this.updateFiltersOnLoad();
  }
}

customElements.define('exceed-filter-select', ExceedFilterSelect);

/*
Component: <exceed-searchable-select>

*/

import { PolymerElement } from '@polymer/polymer';
import axios from "axios";
import PubSub from 'pubsub-js';

import pubSubEvents from '../util/pubsub_event_channels';

import debounce from '../util/debounce';
import { downKeys, upKeys } from '../util/consts';

class ExceedSearchableSelect extends PolymerElement {

  static get properties() {
    return {
      inputElementSelector: {
        // the selector for the main input element that "looks" like a select
        type: String,
        value: '.searchableselect__input'
      },
      inputElementActiveClass: {
        // a class we add to the input element when it is open (for styling)
        type: String,
        value: 'searchableselect__input--open'
      },
      valueInputId: {
        // the id of the input that we populate with the selected item's value (id)
        type: String,
        value: ''
      },
      searchEndpoint: {
        // the endpoint that we use for searching for items
        type: String,
        value: ''
      },
      createEndpoint: {
        // the endpoint that we use for creating items
        type: String,
        value: ''
      },
      createParamsScope: {
        // the params name to scope the creation params
        type: String,
        value: ''
      },
      searchInputSelector: {
        // a selector for the search input/field
        type: String,
        value: '.searchableselect__search'
      },
      contentSelector: {
        // the selector for the dropdown/content of the select-like element
        type: String,
        value: '.searchableselect__content'
      },
      contentTabbablesSelector: {
        // selector for tabbable items in the content
        type: String,
        value: '.searchableselect__tabbablecontent'
      },
      searchResultsSelector: {
        // a selector for the search results element
        type: String,
        value: '.searchableselect__results'
      },
      resultsLoadingClass: {
        // a class we add to the content panel when the content is first opened (to manage initial display)
        type: String,
        value: 'searchableselect__results--loading'
      },
      resultsSelectedClass: {
        // a class we add to the results panel when there is a selected result (to make room for the clear button)
        type: String,
        value: 'searchableselect__results--clearable'
      },
      searchResultItemSelector: {
        // a selector for the selected item
        type: String,
        value: '.searchableselect__resultitem'
      },
      searchResultCreateSelector: {
        // a selector for the create actions
        type: String,
        value: '.searchableselect__create'
      },
      isShowing: {
        // determines whether or not the content/dropdown is showing
        // (listed here mostly so we can use an observer)
        type: Boolean,
        value: false,
        observer: 'setContentVisibility'
      },
      clearValueSelector: {
        // a selector to find the button that will show when a value has been set and allow it to be cleared
        type: String,
        value: '.searchableselect__clear'
      },
      clearValueButtonSelector: {
        // a selector to find the button that will show when a value has been set and allow it to be cleared
        type: String,
        value: '.searchableselect__clearbutton'
      }
    }
  }

  static get is() {
    return 'exceed-searchable-select';
  }

  showFeedback(isSuccess, errorMessage) {
    // TODO: We need to find a nicer way to do this. Not good having an external dependency.
    // We could use a web component for it maybe... A message bus might also help. Not sure.
    if (!window.Intellum || !Intellum.flashnotice) {
      return;
    }
    errorMessage = errorMessage || this.approveErrorMessage;
    if (isSuccess && this.approveSuccessMessage) {
      Intellum.flashnotice.show(this.approveSuccessMessage);
    } else if (!isSuccess && errorMessage) {
      Intellum.flashnotice.show(errorMessage, 'warning');
    }
  }

  /**
   * Monitors changes to isShowing and sets the visibility of the content accordingly
   * */
  setContentVisibility(isShowing) {
    if (this._contentEl) {
      let contentTabbableEls = this._contentEl.querySelectorAll(this.contentTabbablesSelector);
      this._contentEl.hidden = !isShowing;
      if (isShowing) {
        contentTabbableEls.forEach((tabbable) => {
          // Make revealed tabbable items tabbable
          tabbable.removeAttribute('tabindex');
        });
        this._inputEl.setAttribute('aria-expanded', 'true');
      } else {
        contentTabbableEls.forEach((tabbable) => {
        // Make hidden tabbable items untabbable
         tabbable.setAttribute('tabindex', '-1');
        });
        this._inputEl.removeAttribute('aria-expanded');
      }
    }
  }

  /**
   * Clears the value that was selected and closes the select
   * */
  clearValueHandler() {
    this._searchedValue = null;
    this.setValues(false);
    this.closeContent();
  }

  /**
   * Toggles whether or not the clear selection UI is showing
   * */
  toggleClearValueEl() {
    if (!this._clearValueEl) {
      return;
    }

    if (this._valueInputEl.value) {
      this._clearValueEl.hidden = false;
      this._resultsEl.classList.add(this.resultsSelectedClass);
    } else {
      this._clearValueEl.hidden = true;
      this._resultsEl.classList.remove(this.resultsSelectedClass);
    }
  }

  /**
   * Set the values in the input element/s on the page.
   * */
  setValues(selectedItemEl) {
    let selectedItemData = {
      name: '',
      id: ''
    };

    if (selectedItemEl) {
      selectedItemData = selectedItemEl.dataset;
    }

    this._resultsEl.querySelectorAll('[aria-selected]').forEach((selectedEl) => {
      selectedEl.removeAttribute('aria-selected');
    });
    if (selectedItemEl && selectedItemEl.setAttribute) {
      selectedItemEl.setAttribute('aria-selected', 'true');
    }

    if (this._inputEl) {
      this._inputEl.value = selectedItemData.name;
    }
    if (this._valueInputEl) {
      this._valueInputEl.value = selectedItemData.id;
      this._valueInputEl.dispatchEvent(new Event('input'));
      this._valueInputEl.dispatchEvent(new Event('searchableselect.input'));
    }
    this.toggleClearValueEl();
  }

  /**
   * We use event delegation for handling selection (listening for clicks anywhere in the results element and
   * check that the target belongs to a result)
   * */
  selectHandler(event) {
    let closestItemClicked = event.target.closest(this.searchResultItemSelector);
    let closestCreateButtonClicked = event.target.closest(this.searchResultCreateSelector);

    // Check if Create New is clicked
    if (closestCreateButtonClicked) {
      this.createItem(closestCreateButtonClicked.dataset.name);
      return
    }

    if (closestItemClicked) {
      this.setValues(closestItemClicked);
      this.closeContent();
    }
  }

  /**
   * Render the results into the UI
   * */
  renderResults(results) {
    this._resultsEl.scrollTop = 0;
    this._resultsEl.setAttribute('aria-label', Intellum.i18nStrings.search_results);
    this._resultsEl.innerHTML = results;
    this._resultsEl.classList.remove(this.resultsLoadingClass);
  }

  /**
   * Get the results via XHR
   * */
  getResults(searchString) {
    axios.get(this.searchEndpoint, { params: { query: searchString } })
      .then((response) => {
        this.renderResults(response.data);
      });
  }

  /**
   * Create the item via XHR and select it
   * */
  createItem(name) {
    let params = { name: name };
    let scope = this.createParamsScope || '';
    if (scope && scope.size > 0) {
      params[scope] = { params };
    }

    axios.post(this.createEndpoint, params)
      .then((response) => {
        let data = response.data || {}
        this.setValues({ dataset: { name: data.name, id: data.id } });
        this.closeContent();
        // TODO: return translated from controller
        this.showFeedback(true, 'The item was correctly created');
      }).catch((error) => {
        // show feedback
        if (error.response && error.response.data && error.response.data.errors) {
          this.showFeedback(false, error.response.data.errors[0]);
        } else {
          // TODO: return translated from controller
          this.showFeedback(false, 'There was an error with the creation.');
        }
      });
  }

  /**
   * handles the search field input event
   * */
  handleSearchField() {
    if (this._searchInputEl.value !== this._searchedValue) {
      this._searchedValue = this._searchInputEl.value;
      this.getResults(this._searchedValue);
    }
  }

  /**
   * A handler for listening for click events outside of the current element.
   * We use this to close the element if the user clicks away.
   * */
  clickAwayHandler(event) {
    if (!event.target.closest(`#${this.id}`) && event.target != this._valueInputEl) {
      this.closeContent();
    }
  }

  /**
   * Closes the content and resets
   * */
  closeContent() {
    if (this.isShowing) {
      this.isShowing = false;
      this.reset();
    }
  }

  /**
   * Opens the content and inits
   * */
  openContent() {
    this.isShowing = true;
    this.init();
  }

  /**
   * toggles the showing and hiding of the select content
   * (and the population of default content)
   * */
  toggleContent() {
    this.isShowing = !this.isShowing;
    if (this.isShowing) {
      this.init();
    } else {
      this.reset();
    }
  }

  /**
   * Initialise the UI
   * */
  init() {
    this._inputEl.classList.add(this.inputElementActiveClass);
    this._resultsEl.classList.add(this.resultsLoadingClass);
    this._searchInputEl.value = '';
    this._contentEl.focus();
    this.getResults();
    this.toggleClearValueEl();
    this._searchInputEl.focus();
    PubSub.publish(pubSubEvents.escape_lock);
  }

  /**
   * Reset the UI
   * */
  reset() {
    this._inputEl.classList.remove(this.inputElementActiveClass);
    this._resultsEl.innerHTML = '';
    this._resultsEl.classList.add(this.resultsLoadingClass);
    this._inputEl.focus();
    PubSub.publish(pubSubEvents.escape_unlock);
  }

  /**
   * Handles the down arrow key to move to the next item (or search field etc)
   * */
  moveFocusDown(focusedEl) {
    let nextFocusEl;

    if (focusedEl.matches(this.contentSelector)) {
      nextFocusEl = this._searchInputEl;
    } else if (focusedEl.matches(this.searchInputSelector)) {
      nextFocusEl = this._resultsEl.querySelectorAll('[tabindex]')[0];
    } else {
      nextFocusEl = focusedEl.nextElementSibling;
    }

    if (nextFocusEl) {
      nextFocusEl.focus();
    }
  }

  /**
   * Handles the up arrow key to move to the previous item (or search field etc)
   * */
  moveFocusUp(focusedEl) {
    let previousFocusEl = focusedEl.previousElementSibling;

    if (focusedEl.matches(this.searchInputSelector)) {
      // up from search should close the dropdown
      this._inputEl.focus();
    } else if (previousFocusEl) {
      previousFocusEl.focus();
    } else {
      this._searchInputEl.focus();
    }
  }

  /**
   * Handles the home and end keys when moving through the list of items
   * */
  handleHomeAndEnd(homeOrEnd) {
    let selectableOptions = this._resultsEl.querySelectorAll('[tabindex]');
    let selectThisOption;

    if (!selectableOptions.length) {
      return;
    } else if (homeOrEnd === 'home') {
      selectThisOption = selectableOptions[0];
    } else {
      selectThisOption = selectableOptions[selectableOptions.length - 1];
    }

    if (selectThisOption) {
      selectThisOption.focus();
    }
  }

  /**
   * Handle keyboard events (other than the autocomplete/search keyboard events)
   * */
  handleKeyboardEvents(event) {
    if (event.key === 'Escape') {
      if (this.isShowing) {
        event.stopPropagation();
      }
      this._inputEl.focus();
    } else if (upKeys.indexOf(event.key) > -1) {
      event.stopPropagation();
      event.preventDefault();
      this.moveFocusUp(event.target);
    } else if (downKeys.indexOf(event.key) > -1) {
      event.stopPropagation();
      event.preventDefault();
      if (!this.isShowing) {
        this.openContent();
      } else {
        this.moveFocusDown(event.target);
      }
    } else if (event.key === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
      if (!this.isShowing) {
        this.openContent();
      } else if (event.target.closest(this.clearValueSelector)) {
        this.clearValueHandler();
      } else {
        this.selectHandler(event);
      }
    } else if (event.key === ' ' && !event.target.matches(this.searchInputSelector)) {
      event.stopPropagation();
      event.preventDefault();
      if (!this.isShowing) {
        this.openContent();
      }
    } else if (event.key === 'Home' && event.target.closest(this.searchResultsSelector)) {
      event.stopPropagation();
      event.preventDefault();
      this.handleHomeAndEnd('home');
    } else if (event.key === 'End' && event.target.closest(this.searchResultsSelector)) {
      event.stopPropagation();
      event.preventDefault();
      this.handleHomeAndEnd('end');
    } else if (event.key === 'Tab' && event.target.closest(this.searchResultsSelector) && this._clearValueButtonEl) {
      event.preventDefault();
      if (event.shiftKey) {
        this._searchInputEl.focus();
      } else {
        this._clearValueButtonEl.focus();
      }
    }
  }

  /**
   * Bind general/element keyboard and focus events
   * */
  bindKeyboardAndFocusEvents() {
    this._boundKeyboardEventHandler = this.handleKeyboardEvents.bind(this);
    // note - use keydown so that we can stop the form submitting in the case of "Enter" key
    this.addEventListener('keydown', this._boundKeyboardEventHandler);

    this._focusEventHandler = this.closeContent.bind(this);
    this._inputEl.addEventListener('focus', this._focusEventHandler);
  };

  /**
   * Assign the elements that we need to play with
   * */
  setupElements() {
    // the element that acts like a select element (clickable and shows name of item selected etc)
    this._inputEl = this.querySelector(this.inputElementSelector);
    this._contentEl = this.querySelector(this.contentSelector);
    this._searchedValue = ''; // we store the last searched value to avoid more searches (and dom replacements) than we need

    if (this._inputEl) {
      this._boundToggleHandler = this.toggleContent.bind(this);
      this._inputEl.addEventListener('click', this._boundToggleHandler);

      this._boundClickAwayHandler = this.clickAwayHandler.bind(this);
      document.addEventListener('click', this._boundClickAwayHandler);
    }

    // the element that we'll set upon selection of an item
    if (this.valueInputId) {
      this._valueInputEl = document.getElementById(this.valueInputId);
    }

    // the search input
    this._searchInputEl = this.querySelector(this.searchInputSelector);
    if (this._searchInputEl) {
      this._boundSearchHandler = debounce(() => {
        this.handleSearchField();
      }, 500).bind(this);
      this._searchInputEl.addEventListener('keyup', this._boundSearchHandler);
    }

    // the results element (the list of items to choose from)
    this._resultsEl = this.querySelector(this.searchResultsSelector);
    if (this._resultsEl) {
      this._boundSelectHandler = this.selectHandler.bind(this);
      this._resultsEl.addEventListener('click', this._boundSelectHandler);
    }

    // the clear selection element
    this._clearValueEl = this.querySelector(this.clearValueSelector);
    this._clearValueButtonEl = this.querySelector(this.clearValueButtonSelector);
    if (this._clearValueButtonEl) {
      this._boundClearValueHandler = this.clearValueHandler.bind(this);
      this._clearValueButtonEl.addEventListener('click', this._boundClearValueHandler);
    }

    if (this._inputEl && this._searchInputEl) {
      this.bindKeyboardAndFocusEvents();
    }
  }

  connectedCallback() {
    super.connectedCallback();

    if (this.searchEndpoint) {
      this.setupElements();
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    if (this._boundSearchHandler && this._searchInputEl) {
      this._searchInputEl.removeEventListener('keyup', this._boundSearchHandler);
    }

    if (this._resultsEl && this._boundSelectHandler) {
      this._resultsEl.removeEventListener('click', this._boundSelectHandler);
    }

    if (this._inputEl) {
      this._inputEl.removeEventListener('click', this._boundToggleHandler);
      document.removeEventListener('click', this._boundClickAwayHandler);
      this._inputEl.removeEventListener('focus', this._focusEventHandler);
    }

    if (this._boundKeyboardEventHandler) {
      this.removeEventListener('keydown', this._boundKeyboardEventHandler);
    }
  }

  constructor() {
    super();
  }
}

customElements.define('exceed-searchable-select', ExceedSearchableSelect);

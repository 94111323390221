/*
Component: <exceed-form-submit>

*/

import { PolymerElement } from '@polymer/polymer';
import PubSub from "pubsub-js";
import pubSubEvents from "../util/pubsub_event_channels";

class ExceedFormSubmit extends PolymerElement {

  static get properties() {
    return {
      isAlwaysEnabled: {
        // set this to true if the submit button should _always_ be enabled
        // (by default we disable the submit button until the form is valid and/or there is something to submit)
        type: Boolean,
        value: false
      },
      buttonSelector: {
        // the selector for the button that we enable/disable
        type: String,
        value: 'button[type="submit"]'
      },
      dependencyToggleTrigger: {
        // if the contents of the form change due to a dependency toggle we'll need to re-evaluate on each change
        type: String,
        value: ''
      },
      hasFileRequirement: {
        // if there is a required file, either to be added by file input or pre-existing
        type: Boolean,
        value: false
      },
      requiredFileStateSelector: {
        // finds an input field in the containing form that tells whether there's a pre-existing file
        type: String,
        value: 'input[name="has_file"]'
      }
    }
  }

  static get is() {
    return 'exceed-form-submit';
  }

  /**
   * Check if a specific input element has a value (to help determine whether the button should be enabled)
   * */
  doesInputHaveValue(inputEl) {
    let radioEls;

    if (inputEl.getAttribute('type') === 'radio') {
      // for radio buttons ensure that one in the group (by name) is checked
      radioEls = this._formEl.querySelectorAll(`[name="${inputEl.getAttribute('name')}"]`);
      return Array.from(radioEls).some((inputEl) => {
        return inputEl.checked;
      });
    } else if (inputEl.getAttribute('type') === 'checkbox') {
      return inputEl.checked;
    } else {
      return !!inputEl.value;
    }
  }

  doesInputHaveSpecifiedValue(inputEl) {
    return new RegExp(inputEl.getAttribute('pattern')).test(inputEl.value);
  }

  /**
   * Check via observer if the "has_file" input input has change value, disable button if value is now false)
   * */
  checkRequiredFileState(mutationsList, observer) {
    if (mutationsList[0].target.getAttribute('value') === 'false') {
      this.setDisabled(null, true);
    }
  }

  /**
   * Set the button as disabled (or not) depending on whether there are any values to submit AND whether all
   * required fields are set.
   * */
  setDisabled(event, doDisable=false) {
    let isEnabled;

    // disable submit regardless of input values if we got a false argument
    if (doDisable) {
      isEnabled = false;
    } else {
      if (this._requiredInputEls && this._requiredInputEls.length) {
        // if we have required elements, check that they all have a value
        // assume that they do for now and return false as soon as one isn't set
        isEnabled = true;
        Array.from(this._requiredInputEls).some((requiredEl) => {
          if (!this.doesInputHaveValue(requiredEl)) {
            isEnabled = false;
            return;
          } else if (requiredEl.hasAttribute('pattern') && !this.doesInputHaveSpecifiedValue(requiredEl)) {
            isEnabled = false;
          }
        });
      } else {
        // if we have no required elements, check that there is something to submit (i.e. NOT all blanks)
        // assume that they're all empty and return as soon as one is set
        isEnabled = false;
        Array.from(this._formInputEls).some((inputEl) => {
          if (this.doesInputHaveValue(inputEl)) {
            isEnabled = true;
            return;
          }
        });
      }
    }

    if (isEnabled) {
      this._submitButtonEl.removeAttribute('disabled');
    } else {
      this._submitButtonEl.setAttribute('disabled', 'true');
    }
  }

  /**
   * Listen for input events on the form elements of the form
   * */
  bindFormElementEvents() {
    this._boundEventHandlers = [];

    this._formInputEls.forEach((inputEl) => {
      let boundEventHandler = this.setDisabled.bind(this);
      let eventType = 'input';
      const inputTypesThatMonitorChangeEvent = ['checkbox', 'radio', 'hidden', 'select'];

      if (inputTypesThatMonitorChangeEvent.indexOf(inputEl.getAttribute('type')) > -1) {
        eventType = 'change';
      }

      inputEl.addEventListener(eventType, boundEventHandler);

      this._boundEventHandlers.push({
        element: inputEl,
        eventType: eventType,
        handler: boundEventHandler
      });
    });
  }

  /**
   * If there's hidden "has_file" input in the form
   * */
  bindObserver() {
    this._boundRequiredFileStateChangeHandler = this.checkRequiredFileState.bind(this);
    this._requiredFileStateChangeObserver = new MutationObserver(this._boundRequiredFileStateChangeHandler);
    this._requiredFileStateChangeObserver.observe(this._requiredFileStateElement, {attributeFilter: ['value']});
  }

  /**
   * If there's a dependency toggle
   * */
  bindToDependencyToggle() {
    this._dependencyBinding = PubSub.subscribe(pubSubEvents.dependency, (msg, eventData) => {
      if (eventData.trigger === this.dependencyToggleTrigger) {
        this._requiredInputEls = this._formEl.querySelectorAll(this._requiredFieldsSelector);
        this.setDisabled();
      }
    });
  }

  /**
   * Removes any bound event listeners (so that when we use XHR on a form - e.g. remote-form-replace and magic-form) we don't
   * bind multiple times.
   * */
  unbindFormElementEvents() {
    this._boundEventHandlers.forEach((eventBindingRecord) => {
      eventBindingRecord.element.removeEventListener(eventBindingRecord.eventType, eventBindingRecord.handler);
    });
  }

  unbindObserver() {
    this._requiredFileStateChangeObserver.disconnect();
  }

  init() {
    this._submitButtonEl = this.querySelector(this.buttonSelector);
    this._formEl = this.closest('form');
    this._requiredFieldsSelector = '*[required]:not([disabled])';

    if (this._submitButtonEl && this._formEl) {
      this._formInputEls = this._formEl.querySelectorAll('input:not([type=hidden]), textarea, input[type=hidden][required], select');
      this._requiredInputEls = this._formEl.querySelectorAll(this._requiredFieldsSelector);

      // Additional handling if form has hidden "has_file" input with value "true" or "false"
      if (this.hasFileRequirement) {
        this._requiredFileStateElement = this._formEl.querySelector(this.requiredFileStateSelector);
        if (this._requiredFileStateElement) {
          // Do initial check on target field
          if (this._requiredFileStateElement.getAttribute('value') === 'false') {
            this.setDisabled(null, true);
          }
          this.bindObserver();
        }
      }

      if (!this.isAlwaysEnabled) {
        // do an initial set
        this.setDisabled();

        // bind to form events
        this.bindFormElementEvents();

        // bind to dependency toggle events
        if (this.dependencyToggleTrigger) {
          this.bindToDependencyToggle();
        }
      }

      this._formEl.addEventListener('submit', () => {
        this._submitButtonEl.setAttribute('disabled', 'true');
      });
    } else {
      // this is a fairly broad subscription (binding to any modal opening) but it will only happen if things
      // are not yet initialised and is useful for forms that are in an XHR modal - plus we unsubscribe on removal
      this._openModalSubscription = PubSub.subscribe(pubSubEvents.modal_open, () => {
        this.init();
      });
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.init();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    if (this._formInputEls && this._formInputEls.length) {
      this.unbindFormElementEvents();
    }
    if (this._requiredFileStateChangeObserver) {
      this.unbindObserver();
    }
    if (this._openModalSubscription) {
      PubSub.unsubscribe(this._subscribeToSavedEvent);
    }
    if (this._dependencyBinding) {
      PubSub.unsubscribe(this._dependencyBinding);
    }
  }

  constructor() {
    super();
  }
}

customElements.define('exceed-form-submit', ExceedFormSubmit);

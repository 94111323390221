/*
Component: <exceed-dependency-toggle>

Description:
Wrap this around an input element or button, or set of them, if they are to be enabled/disabled or shown/hidden based on selecting other input
or button.

You must add a `trigger` attribute whose value is passed by the trigger element.

If the target is affected by multiple triggers, you can put them in a container and pass the id of that container.

To disable the target, add the attribute/value `doDisable="true"`.
To hide the target from view, use `doHide="true"`.
You can do both.

Use `data-trigger` on the trigger to tell the target whether it is to be
enabled, disabled, or toggled, or whether that depends on the trigger's
value, using one of the PubSub events below.
Add `data-trigger-target` to pass the id of the target element,
and `data-trigger-data` to pass a required json hash with the key
`trigger` whose value matches the expected `trigger` value of this.

Example:
```
<button type="button" id="trigger-element"
data-trigger="dependency.toggle" data-trigger-target="target-element"
data-trigger-data='{"trigger": "trigger-element"}'>button label</button>
<exceed-dependency-toggle id="target-element" trigger="trigger-element"
do-disable="true" do-hide="true">content</exceed-dependency-toggle>
```

Target input can be affected by the change of a `<select>` element,
or a set of same-name radio buttons, using `data-trigger="dependency.value"`
on the trigger (which has `data-trigger-event: "change"` for select or `data-trigger-event: "click"` for radio buttons). And the target inputs can be affected differently for the same trigger value. In the trigger's `data-trigger-data` hash, add the `enabling-values` key, whose value is a hash with
a key of each target's `id`. The value of that is an array that includes each
possible trigger value that should enable/show the target. Any possible trigger
value not listed will disable/hide the target. For example:
`data-trigger-data='{"enabling-values": {"target-element-id1": ["select_value_1", "select_value_2"], "target-element-id2": ["select_value_1"]}'`

For radio buttons as triggers, instead of including the same `enabling-values` hash
in each radio button, you can:
  - Give `enabling-values` a string value that is the id of the container of all of radio buttons;
  - Give that container an attribute of `data-enabling-values` whose value is the hash (in JSON format).

Also, make sure each radio button has a `data-trigger-value` attribute
whose value is the input's value.

Events:

* PubSub
  * Subscribes to:
    * `dependency.disable` to check whether to disable
    * `dependency.enable` to check whether to enable
    * `dependency.toggle` to check whether to toggle enable/disabled
    * `dependency.value` to check whether to enable/disable based on
      the value of the trigger element (such as a select element)
*/

import { PolymerElement } from '@polymer/polymer';
import PubSub from 'pubsub-js';

import pubSubEvents from '../util/pubsub_event_channels';

class ExceedDependencyToggle extends PolymerElement {
  static get is() {
    return 'exceed-dependency-toggle';
  }

  static get properties() {
    return {
      trigger: {
        // string passed by trigger element for this; required
        // may contain more than one string, space-separated
        type: String,
        value: ''
      },
      doDisable: {
        // Pass this as true if the item should be made unavailable by disabling (or available by enabling)
        type: Boolean,
        value: false
      },
      doHide: {
        // Pass this as true if the item should be made unavailable by hiding (or available by showing)
        type: Boolean,
        value: false
      }
    }
  }

  enableElementOrGroupOfElements(el) {
    // for a single form element
    el.removeAttribute('disabled');
    // for a group of form elements (e.g. radio buttons)
    el.querySelectorAll('input, textarea, select, button').forEach((subEl) => {
      subEl.removeAttribute('disabled');
    });
  }

  disableElementOrGroupOfElements(el) {
    // for a single form element
    el.setAttribute('disabled', 'disabled');
    // for a group of form elements (e.g. radio buttons)
    el.querySelectorAll('input, textarea, select, button').forEach((subEl) => {
      subEl.setAttribute('disabled', 'disabled');
    });
  }

  /**
   * Allows us to test whether an event bus event is relevant to the current instance of a dependencyToggle
   * */
  isEventForThisDependencyToggle(eventData) {
    if (!eventData || !eventData.triggerTarget) {
      return false;
    }
    return eventData.triggerTarget === this.id && this.trigger.length && (eventData.trigger == this.trigger || this.trigger.split(' ').includes(eventData.trigger));
  }

  /**
   * Listen for event bus events and handle any events that are directed towards this instance
   * */
  listenToEventBus() {
    PubSub.subscribe(pubSubEvents.dependency, (msg, eventData) => {
      if (this.isEventForThisDependencyToggle(eventData)) {
        if (msg === pubSubEvents.dependency_enable) {
          this.enableDependency();
        } else if (msg === pubSubEvents.dependency_disable) {
          this.disableDependency();
        } else if (msg === pubSubEvents.dependency_toggle) {
          this.toggleDependency();
        } else if (msg === pubSubEvents.dependency_based_on_value && eventData['enabling-values']) {
          this.enableDependencyBasedOnValue(eventData);
        } else if (msg === pubSubEvents.dependency_based_on_value) {
          this.enableDependencyIfValueExists(eventData);
        }
      }
    });
  }

  disableDependency() {
    if (this.doDisable) {
      this.targetEls.forEach((el) => {
        this.disableElementOrGroupOfElements(el);
      })
    }
    if (this.doHide) {
      this.setAttribute('hidden', 'hidden');
    }
  }

  enableDependency() {
    if (this.doDisable) {
      this.targetEls.forEach((el) => {
        this.enableElementOrGroupOfElements(el);
      })
    }
    if (this.doHide) {
      this.removeAttribute('hidden');
    }
  }

  toggleDependency() {
    if (this.doDisable) {
      this.targetEls.forEach((el) => {
        if (el.hasAttribute('disabled')) {
          this.enableElementOrGroupOfElements(el);
        } else {
          this.disableElementOrGroupOfElements(el);
        }
      })
    }
    if (this.doHide) {
      if (this.hasAttribute('hidden')) {
        this.removeAttribute('hidden');
      } else {
        this.setAttribute('hidden', 'hidden');
      }
    }
  }

  // Toggle based on whether a particular value is chosen, as from select
  // or group of same-name radio buttons
  enableDependencyBasedOnValue(eventData) {
    if (eventData["enabling-values"] == undefined || eventData.triggerValue == undefined) {
      return;
    }

    let enablingValues;

    if (typeof eventData["enabling-values"] === 'string') {
      // If value of "enabling-values" is a string, it's pointing to an object elsewhere via id;
      // that object should have `data-enabling-values` with an appropriate hash as value
      if (document.getElementById(eventData["enabling-values"])) {
        enablingValues = JSON.parse(document.getElementById(eventData["enabling-values"]).getAttribute('data-enabling-values'));
      }
    } else {
      // Grab the hash that's in the event data
      enablingValues = eventData["enabling-values"]
    }

    let triggerValueHasTarget = false;
    this.targetEls.forEach((el) => {

      if (Object.keys(enablingValues).includes(el.id)) {
        if (enablingValues[el.id].includes(eventData.triggerValue)) {
          // Target found; flag this for the no-match case after this this forEach loop
          triggerValueHasTarget = true;
          if (this.doDisable) {
            this.enableElementOrGroupOfElements(el);
          }
          if (this.doHide) {
            el.parentElement.removeAttribute('hidden');
            this.setAriaExpandedOnEnablingValue(eventData.triggerValue, enablingValues[el.id]);
          }
        } else {
          if (this.doDisable) {
            this.disableElementOrGroupOfElements(el);
          }
          if (this.doHide) {
            el.parentElement.setAttribute('hidden', 'hidden');
          }
        }
      }
    });

    /* If the triggerValue doesn't show anything (is not in any value array in enablingValues), we need to turn off all aria-expandeds in controls */
    if (!triggerValueHasTarget) {
      this.setAriaExpandedOnEnablingValue(eventData.triggerValue);
    }
  }

  // Set aria-expanded value for actual trigger if target is shown
  // and the element that has the trigger value is not the one matching this.trigger
  // (like, a radio button within the trigger el)
  setAriaExpandedOnEnablingValue(triggerValue, enablingValuesForTarget = []) {
    let triggerEl = document.getElementById(this.trigger);
    if (triggerEl) {
      let valueEl = triggerEl.querySelector(`[value="${triggerValue}"]`);
      if (valueEl) {
        let siblingSelector;
        let valueElSet;
        // Is this a radio button?
        if (valueEl.getAttribute('type') === 'radio') {
          siblingSelector = '[type="radio"]';
        // Or is it a select option?
        } else if (valueEl.tagName === "OPTION") {
          siblingSelector = 'option';
        }
        valueElSet = triggerEl.querySelectorAll(siblingSelector);

        valueElSet.forEach((possibleValueEl) => {
          if (possibleValueEl.hasAttribute('aria-expanded')) {
            if (enablingValuesForTarget.includes(possibleValueEl.value)) {
              possibleValueEl.setAttribute('aria-expanded', 'true');
            } else {
              possibleValueEl.setAttribute('aria-expanded', 'false');
            }
          }
        })
      }
    }
  }

  // Toggle based on whether there is a value
  enableDependencyIfValueExists(eventData) {
    if (eventData.triggerValue != undefined) {
      if (eventData.triggerValue.length) {
        if (this.doDisable) {
          this.targetEls.forEach((el) => {
            this.enableElementOrGroupOfElements(el);
          })
        }
        if (this.doHide) {
          this.removeAttribute('hidden');
        }
      } else {
        if (this.doDisable) {
          this.targetEls.forEach((el) => {
            this.disableElementOrGroupOfElements(el);
          })
        }
        if (this.doHide) {
          this.setAttribute('hidden', 'hidden');
        }
      }
    }
  }

  initElements() {
    /* [data-target-input-holder] can be used on a div so that a doHide for
       dependency.value toggles its parent `<exceed-dependency-toggle>` element; use it for example to contain a field of radio buttons that all
       need to hide or show */
    this.targetEls = this.querySelectorAll('input:not([type="hidden"]), textarea, select, button, [data-target-input-holder]');
  }

  connectedCallback() {
    super.connectedCallback();
    this.initElements();
    this.listenToEventBus();
  }
}

customElements.define('exceed-dependency-toggle', ExceedDependencyToggle);


/*
Component: <exceed-uploaded-image>
Usage:
Notes:

* listens for a matching "uploadfile.change" event from PubSub and updates the image accordingly
* TODO: Allows for polling of a "processingUrl" endpoint - to update itself when async processing is complete

Events:

* PubSub
  * Listens for an 'uploadfile.change' event that matches the "uploadField" attribute.
  * Publishes 'uploadfile.added' when an image has been added.

*/

import { PolymerElement } from '@polymer/polymer';
import PubSub from 'pubsub-js';

import pubSubEvents from '../util/pubsub_event_channels';
import axios from "axios";

class ExceedUploadedImage extends PolymerElement {

  static get properties() {
    return {
      emptyClass: {
        type: String,
        value: 'uploadedimage--empty'
      },
      savingClass: {
        type: String,
        value: 'uploadedimage--saving'
      },
      uploadField: {
        // the id of the field that this image is a preview of
        type: String,
        value: ''
      },
      pollingPath: {
        type: String,
        value: ''
      },
      pollingInterval: {
        type: Number,
        value: 10000
      },
      imageStyle: {
        // use this to allow for asking for a particular rendition/style of the image
        type: String,
        value: ''
      },
      defaultImageContainerSelector: {
        // use this if elsewhere there's a default image to be displayed when no image has been selected
        type: String,
        value: ''
      },
      isVideo: {
        type: Boolean,
        value: false
      },
      videoFileNameSelector: {
        type: String,
        value: ".uploadedimage__videofilename"
      }
    }
  }

  static get is() {
    return 'exceed-uploaded-image';
  }

  updateImage(newImageSrc, status, fileName) {
    console.log(newImageSrc)
    console.log(status);
    console.log(fileName);

    if (this.isVideo) {
      if (this.videoFileNameEl && fileName) {
        this.videoFileNameEl.innerText = fileName;
      }
    } else if (newImageSrc) {
      if (this.imageEl) {
        // Save the old src in case this gets canceled
        this.oldImageSrc = this.imageEl.getAttribute('src');
      } else {
        // Add an img element if we need one and don't have one
        this.addImgEl();
      }
    }

    if (this.imageEl) {
      this.imageEl.hidden = false;
      this.imageEl.setAttribute('src', newImageSrc);
    }
    this.classList.remove(this.emptyClass);

    if (this.defaultImageEl) {
      this.defaultImageEl.setAttribute('hidden', 'hidden');
    }

    setTimeout(() => {
      if (status === 'saving') {
        this.classList.add(this.savingClass);
      } else {
        this.classList.remove(this.savingClass);
        this.newImageSrc = newImageSrc;

        // Publish event that this has been done
        PubSub.publish(pubSubEvents.uploadfile_added, {});
      }
    }, 500);
  }

  revertImage() {
    if (this.imageEl) {
      // Revert if we have an image that matches what was uploaded ...
      if (this.newImageSrc.length && this.imageEl.getAttribute('src') == this.newImageSrc) {
        if (this.oldImageSrc.length) {
          this.imageEl.setAttribute('src', this.oldImageSrc);
        } else {
          this.hideImage();
          this.classList.add(this.emptyClass);
        }
      // ... Or, if there was an old image that we hid, unhide it
      } else if (this.imageEl.getAttribute('src').length && (this.imageEl.hidden || this.classList.contains(this.emptyClass))) {
        this.unhideImage();
      }
    } else {
      if (!this.isVideo) {
        // In the unlikely event there's no img element, add one for the original img
        if (this.oldImageSrc.length) {
          this.addImgEl();
          this.imageEl.setAttribute('src', this.oldImageSrc);
        }
      }
    }
    // Reset markers for old and new image
    this.oldImageSrc = '';
    this.newImagesrc = '';
  }

  // Add empty img when there's no img element
  addImgEl() {
    let newImageEl = document.createElement('img');
    this.appendChild(newImageEl);
    this.imageEl = newImageEl;
  }

  hideImage() {
    if (this.imageEl) {
      this.imageEl.hidden = true;
    }
    this.classList.add(this.emptyClass);

    if (this.defaultImageEl) {
      this.defaultImageEl.removeAttribute('hidden');
    }
  }

  unhideImage() {
    this.classList.remove(this.emptyClass);
    if (this.imageEl) {
      this.imageEl.hidden = false;
    }

    if (this.defaultImageEl) {
      this.defaultImageEl.setAttribute('hidden', 'hidden');
    }
  }

  listenForPubSubEvents() {
    PubSub.subscribe(pubSubEvents.uploadfile, (msg, eventData) => {
      if (eventData.uploadField !== this.uploadField) {
        return;
      }

      console.log("MSG", msg);

      if (msg === pubSubEvents.uploadfile_change) {
        this.updateImage(eventData.url, eventData.status, eventData.fileName);
      } else if (msg === pubSubEvents.uploadfile_error) {
        this.updateImage(this.cachedImagePath);
      } else if (msg === pubSubEvents.uploadfile_delete) {
        this.hideImage();
      } else if (msg === pubSubEvents.uploadfile_processing) {
        this.isProcessing = true;
        this.pollingPath = eventData.processing_url;
        this.startPolling();
      }
    });
    PubSub.subscribe(pubSubEvents.form, (msg, eventData) => {
      if (msg === pubSubEvents.form_revert) {
        this.revertImage();
      }
    })
  }

  cacheExistingImagePath() {
    if (this.imageEl) {
      this.cachedImagePath = this.imageEl.getAttribute('src');
    }
  }

  startPolling() {
    if (this.isProcessing) {
      this.pollingInterval = setInterval(() => {
        this.pollForCompletion();
      }, this.pollingInterval);
    }
  }

  pollForCompletion() {
    axios.get(this.pollingPath)
      .then((response) => {
        if (response.data.status === 'complete') {
          clearInterval(this.pollingInterval);
          this.updateImage(response.data.url);
          this.isProcessing = false;
        }
      });
  }

  handleProcessingOnPageLoad() {
    if (this.pollingPath) {
      this.isProcessing = true;
      this.startPolling();
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.imageEl = this.querySelector('img');
    this.oldImageSrc = '';
    this.newImageSrc = '';
    if (this.isVideo) {
      this.videoFileNameEl = this.querySelector(this.videoFileNameSelector);
    }
    if (this.defaultImageContainerSelector) {
      this.defaultImageEl = document.querySelector(this.defaultImageContainerSelector);
    }
    this.listenForPubSubEvents();
    this.cacheExistingImagePath();
    this.handleProcessingOnPageLoad();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    // Prevent image from being replaced by other instances of component
    this.imageEl = null;
  }
}

customElements.define('exceed-uploaded-image', ExceedUploadedImage);

/*
Component: <exceed-toggle-button>
Usage:
Notes:
*/

import { PolymerElement } from '@polymer/polymer';

class ExceedToggleButton extends PolymerElement {

  static get properties() {
    return {
      targetSelector: {
        type: String,
        value: ''
      },
      targetFocus: {
        type: String,
        value: ''
      },
      toggleClass: {
        type: String,
        value: ''
      },
      triggerSelector: {
        type: String,
        value: ''
      },
      triggerToggleClass: {
        type: String,
        value: ''
      },
      pauseTransitionClass: {
        type: String,
        value: ''
      },
      pauseTransitionTime: {
        type: Number,
        value: 1000
      },
      preventBodyScroll: {
        type: Boolean,
        value: false
      },
      hasDynamicContent: {
        // if this is true we will check for all matching target items every time the toggle is clicked
        type: Boolean,
        value: false
      },
      disableHiddenContent: {
        // Make toggled content disabled when hidden
        type: Boolean,
        value: false
      }
    }
  }

  static get is() {
    return 'exceed-toggle-button';
  }

  handleBodyScroll(doFreezeTheBoddy) {
    const bodyFreezeClass = 'body--noscroll';

    if (doFreezeTheBoddy) {
      document.body.classList.add(bodyFreezeClass);
    } else {
      document.body.classList.remove(bodyFreezeClass);
    }
  }

  doToggle() {
    if (this.triggerSelector.length) {
      this.triggerEl = this.querySelector(this.triggerSelector);
    };
    this.targetEls = document.querySelectorAll(this.targetSelector);

    this.addEventListener('click', () => {
      let doToggleOn = document.querySelectorAll(this.targetSelector).length > document.querySelectorAll(`.${this.toggleClass}`).length;
      if (this.hasDynamicContent) {
        this.targetEls = document.querySelectorAll(this.targetSelector);
      }

      this.targetEls.forEach((targetEl) => {
        if (doToggleOn) {
          if (this.pauseTransitionClass) {
            // the pauseTransitionClass must be added in the view and we remove it here
            // then we then restore it whenever we remove the toggled class (after a delay).
            // this prevents the "occasional premature transitions on iOS when you swipe back" bug
            targetEl.classList.remove(this.pauseTransitionClass);
          }
          targetEl.classList.add(this.toggleClass);
          targetEl.removeAttribute('aria-hidden');
          if (this.triggerEl) {
            this.triggerEl.classList.add(this.triggerToggleClass);
            this.triggerEl.setAttribute('aria-expanded', 'true');
          } else {
            this.setAttribute('aria-expanded', 'true');
          }
          if (this.disableHiddenContent) {
            targetEl.removeAttribute('disabled');
          }
        } else {
          targetEl.classList.remove(this.toggleClass);
          targetEl.setAttribute('aria-hidden', 'true');
          if (this.triggerEl) {
            this.triggerEl.classList.remove(this.triggerToggleClass);
            this.triggerEl.setAttribute('aria-expanded', 'false');
          } else {
            this.setAttribute('aria-expanded', 'false');
          }
          if (this.disableHiddenContent) {
            targetEl.setAttribute('disabled', 'disabled');
          }
          window.setTimeout(() => {
            if (this.pauseTransitionClass) {
              // The delay value leaves time for a reasonable transition-duration rather than force us to match the css value
              // though the pause time can be set on the element
              targetEl.classList.add(this.pauseTransitionClass);
            }
          }, this.pauseTransitionTime);
        }
        this.isToggledOn = !this.isToggledOn;
      });

      if (this.targetFocus.length && !document.body.classList.contains('a11y-nofocus')) {
        window.setTimeout(() => {
          document.querySelector(this.targetFocus).focus();
        }, 100);
      };

      this.handleBodyScroll(this.preventBodyScroll && doToggleOn);
    });
  }

  connectedCallback() {
    super.connectedCallback();
    this.doToggle();
  }
}

customElements.define('exceed-toggle-button', ExceedToggleButton);

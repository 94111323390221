/**
 * Utility methods related to accessibility:
 * - Trapping and releasing focus in a modal or dialog
 *   (taken from Intellum.a11y.trapFocus)
 * */

// Function to trap focus in a given container (usually a dialog or modal)
//    parentEl = the container in which we want to trap focus
//    initialFocusEl = designated element to focus on first (optional)
export const trapFocus = (parentEl, initialFocusEl) => {
  let transitionEl, parentStyles, durationString, duration, delayString, delay;
  let focusables = null;

  const focusableSelector = [
      'a[href]', 'area[href]',
      'input:not([disabled]):not([type="hidden"]):not(.hidden)',
      'select:not([disabled])', 'textarea:not([disabled])',
      'button:not([disabled])', 'iframe', 'object', 'embed',
      '*[tabindex="0"]', '*[contenteditable]'
    ].join(', ');

  const setInitialFocus = () => {
    transitionEl.removeEventListener('transitionend', setInitialFocus);
    initialFocusEl.focus();
  }

  const isElementVisible = (element) => {
    if (!element) return true;
    if (window.getComputedStyle(element).display === 'none') return false;
    return isElementVisible(element.parentElement);
  };

  const getFocusableElements = () => focusables.filter(isElementVisible);

  const handleTabKey = (parentEl, ev) => {
    const visibleFocusables = getFocusableElements(focusables);

    // Watch for Tab keys
    if (ev.key === "Tab") {

      let currentIdx = visibleFocusables.indexOf(document.activeElement);

      // If the focus "escaped" thecontainer, bring it back.
      if (focusables.length > 0 && !parentEl.contains(document.activeElement)) {
        visibleFocusables[0].focus();
      } else if (ev.shiftKey) {
        if (currentIdx === 0) {
          visibleFocusables.at(-1).focus();
          ev.preventDefault();
        }
      } else {
        if (currentIdx === (visibleFocusables.length - 1)) {
          visibleFocusables[0].focus();
          ev.preventDefault();
        }
      }
    }
  }

  // Get a list of all the focusable elements within the parentEl
  focusables = Array.from(parentEl.querySelectorAll(focusableSelector));

  // If the current focused element isn't within the container,
  // make it so by setting focus to the specified element or 1st focusable element
  initialFocusEl = initialFocusEl ||
      (document.activeElement && parentEl.contains(document.activeElement)) ||
      (focusables.length > 0 && getFocusableElements()[0]);

  // We may need to wait on a transition end before setting the initial focus;
  // Safari will scroll to bring the initially focused element into the viewport,
  // even if such scrolling wouldn't be required after the transition ends
  parentStyles = window.getComputedStyle(parentEl);
  durationString = parentStyles.getPropertyValue('transition-duration');
  duration = durationString && durationString.split(',').
              some(function(prop) { return prop && window.parseInt(prop) !== 0; });
  delayString = parentStyles.getPropertyValue('transition-delay');
  delay = delayString && delayString.split(',').
              some(function(prop) { return prop && window.parseInt(prop) !== 0; });
  if (duration || delay) {
    transitionEl = parentEl;
    return true;
  }

  if (initialFocusEl) {
    if (transitionEl && document.body.style.transition !== 'undefined') {
      transitionEl.addEventListener('transitionend', setInitialFocus);
    } else {
      initialFocusEl.focus();
    }
  }

  // Install our keyboard handler
  if (focusables.length > 0) {
    let boundTabKeydownHandler = handleTabKey.bind(this, parentEl);
    document.addEventListener('keydown', boundTabKeydownHandler);

    // We need to return the bound event handler so it can be passed to #releaseFocus and removed
    return {boundTabKeydownHandler: boundTabKeydownHandler};
  }
  return {};
};

// Function to release trapped focus
//    prevFocusEl = element that had focus when #trapFocus was called
//    boundKeydownHandler = handler returned by #trapFocus
export const releaseFocus = (prevFocusEl, boundTabKeydownHandler) => {
  // Remove our keyboard handler
  if (boundTabKeydownHandler) {
    document.removeEventListener("keydown", boundTabKeydownHandler);
  }

  // Restore focus
  if (prevFocusEl && prevFocusEl.focus) {
    prevFocusEl.focus();
  }
};

/*
Component: <exceed-validate-autocomplete>
Usage:
Notes: WIP for managing validation with auto complete - needs some work - would be better if it wasn't
wrapped around the whole form.

*/

import { PolymerElement } from '@polymer/polymer';
import axios from '../util/axios';
import serialize from 'form-serialize';

class ExceedValidateAutocomplete extends PolymerElement {

  static get is() {
    return 'exceed-validate-autocomplete';
  }

  static get properties() {
    return {
      parentDialogId: {
        type: String,
        value: ''
      },
      isFormValid: {
        type: Boolean,
        value: false,
        observer: 'toggleSubmitButton'
      },
      editFormId: {
        type: String,
        value: 'edit-activity-form'
      },
      selectedItemIdId: {
        type: String,
        value: 'add-course-to-topic-id'
      },
      autoCompleteSelector: {
        type: String,
        value: 'exceed-autocomplete-simple'
      },
      warningMessageId: {
        type: String,
        value: 'add-item-warning'
      },
      generalErrorMessage: {
        type: String,
        value: "Did not select an item"
      }
    }
  }

  toggleSubmitButton(currentIsFormValidValue) {
    let submitButtonEl = this._submitButtonEl || this.querySelector(`#${this.editFormId} button[type="submit"]`);
    if (currentIsFormValidValue) {
      submitButtonEl.removeAttribute('disabled');
    } else {
      submitButtonEl.setAttribute('disabled', 'disabled');
    }
  }

  toggleItemSelectionWarning(forceHide) {
    let warningEl;
    if (!warningEl) {
      warningEl = this.querySelector(`#${this.warningMessageId}`);
    }
    if (this._selectedItemIdEl.value || forceHide) {
      warningEl.style.display = 'none';
    } else {
      warningEl.style.display = 'block';
    }
  }

  monitorAutoComplete() {
    if (this._autoCompleteEl) {
      this._autoCompleteEl.addEventListener('autocompletesimple.search', () => {
        this._selectedItemIdEl.value = '';
      });
      this._autoCompleteInputEl.addEventListener('blur', () => {
        setTimeout(() => {
          this.toggleItemSelectionWarning();
          this.checkRequiredFields();
        }, 500);
      });
    }
  }

  checkRequiredFields() {
    let isSomeEmptyRequiredFields;
    let isItemSelected = this._selectedItemIdEl.value;

    isSomeEmptyRequiredFields = Array.from(this._requiredFieldEls).some((field) => {
      return !field.value;
    });

    this.isFormValid = isItemSelected && !isSomeEmptyRequiredFields;
  }

  monitorRequiredFields() {
    this._requiredFieldEls.forEach((requiredFieldEl) => {
      requiredFieldEl.addEventListener('keyup', () => {
        this.checkRequiredFields();
      });
    });
  }

  bindEditFormSubmitButton() {
    this._submitButtonEl.addEventListener('click', (event) => {
      event.preventDefault();
      if (!this._submitButtonEl.hasAttribute('disabled')) {
        this.checkRequiredFields();
        if (this.isFormValid) {
          this._editFormEl.submit();
        }
      }
    });
  }

  clearForm() {
    this.toggleItemSelectionWarning(true);

    this._editFormEl.querySelectorAll(`[${this.defaultValuesAttribute}]`).forEach((fieldEl) => {
      fieldEl.value = fieldEl.getAttribute(this.defaultValuesAttribute);
    });
  }

  init() {
    this._editFormEl = this.querySelector(`#${this.editFormId}`);
    this._submitButtonEl = this._editFormEl.querySelector('button[type="submit"]');
    this._autoCompleteEl = this.querySelector(this.autoCompleteSelector);
    this._autoCompleteInputEl = this._autoCompleteEl.querySelector('input');
    this._selectedItemIdEl = this.querySelector(`#${this.selectedItemIdId}`);
    this._requiredFieldEls = this.querySelectorAll('input[required]');

    if (this.parentDialogId) {
      this._dialogEl = document.getElementById(this.parentDialogId);
    }

    if (this._dialogEl) {
      this._dialogEl.addEventListener('show.dialog', () => {
        this.clearForm();
        this.monitorAutoComplete();
        this.monitorRequiredFields();
        this.bindEditFormSubmitButton();
      });
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.init();
  }
}

customElements.define('exceed-validate-autocomplete', ExceedValidateAutocomplete);
